import React from 'react';
import { Grid, Typography, Avatar } from '@material-ui/core';
import Style from './leaderboard.module.scss';
import { CardMUI } from '../../lib';
import { TopAgentsData } from '../../../data/mockData';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		'& > *': {
			margin: theme.spacing(1),
		},
	},
	small: {
		width: theme.spacing(3),
		height: theme.spacing(3),
	},
	large: {
		width: theme.spacing(8),
		height: theme.spacing(8),
		margin: '0.5em 0',
	},
	placeholderColor: {
		color: Style['c-black-Module'],
		backgroundColor: Style['c-black-Background'],
	},
}));

export function TopPerformersCard(props) {
	const Data = TopAgentsData;
	const classes = useStyles();
	return (
		<div className={Style.gridAgents}>
			{Data.map((i, index) => {
				return (
					<Grid item className={Style.gridItem} key={index}>
						<CardMUI
							customClass={
								index < 1
									? Style['grid-card-start']
									: index === Data.length - 1
									? Style['grid-card-end']
									: Style['grid-card']
							}
						>
							<Avatar
								className={`${classes.large} ${classes.placeholderColor}`} //Style['card-avatar']
								src={i.avatar}
								alt={i.name}
							>
								{i.avatar === null ? null : ( //for demo, to deside what kind of placeholder to use
									<Typography variant='h3'>
										{i.name
											.split(' ')
											.map((n) => n[0])
											.join('')}
									</Typography>
								)}
							</Avatar>

							<Typography color='textPrimary' variant='subtitle1'>
								{i.name}
							</Typography>
							<Typography color='textSecondary' variant='h5'>
								{i.label}
							</Typography>
						</CardMUI>
					</Grid>
				);
			})}
		</div>
	);
}
