import React from 'react';
import Highcharts from 'highcharts';
import * as _ from 'lodash';

export class LineChart extends React.Component {
	container = React.createRef();
	chart = null;

	componentDidMount() {
		const data = _.times(12, (index) => ({
			x: index,
			y: _.random(-1, 1) * _.random(20),
		}));
		const data2 = _.times(12, (index) => ({
			x: index,
			y: _.random(-1, 1) * _.random(20),
		}));
		const data3 = _.times(12, (index) => ({
			x: index,
			y: _.random(-1, 1) * _.random(20),
		}));
		const data4 = _.times(12, (index) => ({
			x: index,
			y: _.random(-1, 1) * _.random(20),
		}));
		const CurrentDate = 7.7;
		this.chart = Highcharts.chart(this.container.current, {
			title: {
				text: '',
				enabled: false,
			},
			chart: { backgroundColor: 'transparent' },
			yAxis: {
				labels: {
					formatter: function () {
						return Math.sign(this.value) === 1
							? `$${this.value}K`
							: Math.sign(this.value) === -1
							? `-$${this.value * -1}K`
							: `$${this.value}K`;
					},
					style: {
						color: '#8B8BA1',
					},
				},
				gridLineWidth: 0,
				title: {
					enabled: false,
				},
				plotBands: [
					{
						from: -6000000,
						to: 0,
						color: '#20262D',
					},
				],
			},
			xAxis: {
				categories: [
					'Jan',
					'Feb',
					'Mar',
					'Apr',
					'May',
					'Jun',
					'Jul',
					'Aug',
					'Sep',
					'Oct',
					'Nov',
					'Dec',
				],
				labels: {
					reserveSpace: false,
					step: 1,
				},

				title: { text: '' },
				tickWidth: 1,

				plotLines: [
					{
						color: '#8B8BA1',
						width: 3,
						value: CurrentDate, // var.
						zIndex: 1,
					},
				],
			},
			plotOptions: {
				series: {
					label: {
						connectorAllowed: false,
					},
					pointStart: 2010,
				},
			},
			legend: {
				align: 'right',
				verticalAlign: 'top',
				margin: 20,
				padding: -10,
				width: 400,
				layout: 'horizontal',
				itemStyle: { color: '#fff' },
				dashStyle: 'dot',
			},
			series: [
				{
					data,
					name: 'FB',
					color: '#C361E5',
					zoneAxis: 'x',
					zones: [
						{
							value: CurrentDate, //var
						},
						{
							dashStyle: 'dot',
						},
					],
				},
				{
					name: 'Zillow',
					color: '#6179E5',
					data: data2,
					zoneAxis: 'x',
					zones: [
						{
							value: CurrentDate, //var
						},
						{
							dashStyle: 'dot',
						},
					],
				},
				{
					data: data3,
					name: 'Google',
					color: '#61E5CD',
					zoneAxis: 'x',
					zones: [
						{
							value: CurrentDate, //var
						},
						{
							dashStyle: 'dot',
						},
					],
				},
				{
					data: data4,
					name: 'Other',
					color: '#E5B061',
					zoneAxis: 'x',
					zones: [
						{
							value: CurrentDate, //var
						},
						{
							dashStyle: 'dot',
						},
					],
				},
			],
			credits: {
				enabled: false,
			},
		});
	}

	render() {
		return (
			<>
				<div ref={this.container} style={{ width: '95%' }} />
			</>
		);
	}
}
