/***
 *
 *   Members
 *   Manage your members
 *
 **********/

import React from 'react';

import { Grid, Divider } from '@material-ui/core';

import { NavTabs, History } from 'components/lib';

import { MembersTemp } from './membersTemp';

const Tabs = [
	{ label: 'Members', link: '/users_managment/members' },
	{ label: 'Invites', link: '/users_managment/invites' },
];

export function Members(props) {
	const handleViewChange = (event) => {
		if (event === 1) History.push('/users_managment/invites');
	};

	return (
		<>
			<Grid
				container
				direction='column'
				justify='flex-start'
				style={{
					paddingTop: '5em',
					maxWidth: '72em',
					minHeight: '100vh',
					alignItems: 'center',
				}}
			>
				<NavTabs
					changeView={handleViewChange}
					items={Tabs}
					defaultValue={0}
					disableSwitch
				/>
				<Divider style={{ marginBottom: '3em', height: 0 }} />
				<MembersTemp />
				<Divider style={{ marginBottom: '3em', height: 0 }} />
			</Grid>
		</>
	);
}
