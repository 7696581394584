import React, { useContext, useEffect, useState } from 'react';

import { apiAxiosCall } from 'components/lib';

import { appMenuItems } from './navOptions';
const logoTemplate = { type: 'name', val: 'test' };
const NavContext = React.createContext({
	menuItems: appMenuItems,
	selectedLogo: false,
	logoData: false,
	updateTree: () => {},
	getRoutes: () => {},
	setUpdateLogo: () => {},
	getLogoData: () => {},
});

export const useNavItems = () => {
	return useContext(NavContext);
};

export const NavProvider = (props) => {
	const [menuItems, setMenuItems] = useState();
	const [activeRoute, setActiveRoute] = useState();
	const [selectedLogo, setLogo] = useState(false);
	const [logoData, setLogoData] = useState(false);
	//const [user, setUser] = useState(false);

	//let newUser = false;
	// useEffect(() => {
	// 	newUser = { ...JSON.parse(localStorage.getItem('user')) };
	// 	setUser(newUser);
	// }, [newUser]);
	const updateTree = (parentIndex, childIndex, activeRout) => {
		let newMenuItems = [...menuItems];
		if (activeRout) {
			setActiveRoute([parentIndex, childIndex]);
			if (activeRoute) {
				newMenuItems[activeRoute[0]].isActive = false;

				if (activeRoute[1] !== false)
					newMenuItems[activeRoute[0]].items[activeRoute[1]].isActive = false;
			}

			newMenuItems[parentIndex].isActive = true;

			if (childIndex !== false) {
				newMenuItems[parentIndex].items[childIndex].isActive = true;
				newMenuItems[parentIndex].isOpen = true;
			}

			return setMenuItems(newMenuItems);
		}

		const bool = newMenuItems[parentIndex].isOpen;
		newMenuItems[parentIndex].isOpen = !bool;

		return setMenuItems(newMenuItems);
	};

	//const removeMenuCategory = (category) => {};
	const expandMenuItems = (appMenuItemsExpanded) => {
		//map through menu items and add required options
		appMenuItemsExpanded.map((item, index) => {
			//is this rout active
			appMenuItemsExpanded[index].isActive = false;
			//if nav item has child items then set as expandable
			item.items &&
				(appMenuItemsExpanded[index].isExpandable =
					item.items && item.items.length > 0);
			//if this item is expandable, then it can be opened
			appMenuItemsExpanded[index].isExpandable === true &&
				(appMenuItemsExpanded[index].isOpen = false);

			//map through child items if any
			item.items &&
				item.items.map((childItem, childIndex) => {
					appMenuItemsExpanded[index].items[childIndex].isActive = false;
					return childItem;
				});
			return item;
		});

		return appMenuItemsExpanded;
	};

	const setUpdateLogo = (type) => {
		let logo = { ...logoTemplate };
		switch (type) {
			case 'name':
				logo.type = type;
				logo.val = logoData['name'];
				break;
			case 'initials':
				let initials = logoData['name'].match(/\b\w/g).join('') || '';
				logo.type = type;
				logo.val = initials;
				break;
			case 'picture':
				logo.type = type;
				logo.val = logoData['picture'];
				break;
			default:
				break;
		}

		setLogo((prevState) => {
			return {
				...prevState,
				...logo,
			};
		});
	};

	useEffect(() => {
		//when provider is mounted
		//expand nav menu options that imported from navOptions

		//console.log('menuItems', appMenuItems);
		setMenuItems(expandMenuItems(appMenuItems));
		getLogoData();
	}, []);

	const getRoutes = (arg) => {
		const user = { ...JSON.parse(localStorage.getItem('user')) };
		const availableRoutes = [];
		return availableRoutes;
	};

	const requestLogoData = async () => {
		try {
			const res = await apiAxiosCall(`/api/organization`).then((res) => {
				if (res.err) return false;
				return { picture: res.data.avatarId, name: res.data.name };
			});
			return res;
		} catch (e) {
			console.log('error in api/organization');
			return false;
		}
	};

	const getLogoData = async (forceUpdate) => {
		const logoData = await requestLogoData();
		if (logoData) setLogoData(logoData);
	};

	useEffect(() => {
		if (!selectedLogo && logoData) {
			let logo = { ...logoTemplate };
			logo.type = 'name';
			logo.val = logoData['name'];
			setLogo((prevState) => {
				return {
					...prevState,
					...logo,
				};
			});
		}
	}, [logoData]);

	return (
		<NavContext.Provider
			value={{
				menuItems: menuItems,
				selectedLogo,
				updateTree,
				getRoutes,
				setUpdateLogo,
				getLogoData,
			}}
		>
			{props.children}
		</NavContext.Provider>
	);
};
