import React from 'react';
import Axios from 'axios';

// components
import { Router, Route, Switch } from 'react-router-dom';

import { PrivateRoute, AuthProvider } from './auth';
import {
	History,
	View,
	NavProvider,
	AgentProvider,
	RoleProvider,
	MemberProvider,
} from 'components/lib';

// import { loadStripe } from '@stripe/stripe-js';
// import { Elements } from '@stripe/react-stripe-js';

// 404
import { ErrorPage as NotFound } from 'views/website/404';

// settings
//const Settings = require('settings.json');
// const StripePromise = loadStripe(
// 	Settings[process.env.NODE_ENV].stripe.publishableAPIKey
// );

const Routes = [
	...require('routes/setup').default,
	...require('routes/account').default,
	...require('routes/app').default,
	...require('routes/auth').default,
	...require('routes/website').default,
	...require('routes/master').default,
];

export default function App(props) {
	const user = JSON.parse(localStorage.getItem('user'));

	if (user?.accessToken) {
		// add auth token to api header calls & log last_active
		Axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.accessToken;
		Axios.defaults.baseUrl = process.env.REACT_APP_API_URI;
		// Axios.patch('/api/user/', {
		// 	last_active: new Date().toISOString().slice(0, 19).replace('T', ' '),
		// });
	}

	// render the routes
	return (
		// <Elements stripe={StripePromise}>
		<AuthProvider>
			<NavProvider>
				<AgentProvider>
					<RoleProvider>
						<MemberProvider>
							<Router history={History}>
								<Switch>
									{Routes.map((route) => {
										const RouteType = route.permission ? PrivateRoute : Route;

										return (
											<RouteType
												key={route.path}
												exact
												path={route.path}
												permission={route.permission}
												render={(data) => (
													<div>
														<View
															display={route.view}
															layout={route.layout}
															title={route.title}
															data={data}
														/>
													</div>
												)}
											/>
										);
									})}

									{/* 404 */}
									<Route
										render={() => (
											<View display={NotFound} layout='homeMUI' title='404 Not Found' />
										)}
									/>
								</Switch>
							</Router>
						</MemberProvider>
					</RoleProvider>
				</AgentProvider>
			</NavProvider>
		</AuthProvider>
		// </Elements>
	);
}
