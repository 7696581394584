/***
 *
 *   APP LAYOUT WITH MATERIAL UI CUSTOM COMPONENTS
 *   Main application layout containing the navigation
 *   and header (title, secondary nav and signed in user)
 *
 *   PROPS
 *   title: title of the view
 *
 **********/

import React, { Fragment } from 'react';
import { AppNavMUI, HeaderMUIGen } from '../../lib';
//import { AuthContext } from '../../../components/lib';
import ClassNames from 'classnames';
import Style from './appMUI.module.scss';
import '../layoutMUI.scss'; // globals

import { CssBaseline } from '@material-ui/core';
//import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles';

import { darkTheme } from 'components/global/material';
//font
const isAuthFailed = () => {
	return !localStorage.getItem('user') || !localStorage.getItem('accessToken');
};
export function AppLayoutMUI(props) {
	const css = ClassNames([Style.app, 'with-sidebarMUI']);
	if (isAuthFailed()) {
		window.location = '/signin';
		return null;
	}
	const handleClick = () => console.log('header btn');
	return (
		<Fragment>
			<ThemeProvider theme={darkTheme}>
				<CssBaseline />
				<AppNavMUI />
				<main className={css}>
					<HeaderMUIGen
						title={props.title}
						type='largeHeader'
						//	btn
						btnTitle='edit'
						btnOnClick={() => handleClick()}
					></HeaderMUIGen>
					{/* <NavTabs /> */}

					{<props.children {...props.data} />}
				</main>
			</ThemeProvider>
		</Fragment>
	);
}
