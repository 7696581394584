import React from 'react';

import List from '@material-ui/core/List';

import AppMenuItem from './menuItem';

import { useNavItems } from 'components/lib';

const AppNav = () => {
	const { menuItems } = useNavItems();

	if (!menuItems) return null;
	return (
		<List component='nav' disablePadding>
			{menuItems &&
				//menuItems == menuItems &&
				menuItems.map((item, index) => (
					<AppMenuItem {...item} key={index} parentIndex={index} />
				))}
		</List>
	);
};

export default AppNav;
