/***
 *

 *
 **********/

import React, { useEffect, useState } from 'react';

import { Button } from '@material-ui/core';

import {
	ContentSection,
	TableList,
	History,
	useRoleItems,
	PageWrapper,
} from 'components/lib';

export function RolesList(props) {
	const [state, setState] = useState({
		loading: true,
		roles: null,
		permissions: null,
	});

	const {
		setCurrentRoleFn,
		allRoles,
		deleteRoleFn,
		duplicateRoleFn,
		addRoleFn,
		clearSelectedRoleFn,
	} = useRoleItems();

	//eslint-disable-next-line no-unused-vars
	const compileList = (apiData) => {
		setState({ ...state, roles: false });
		let roles = {
			headerName: '',
			labels: ['id ', 'title', 'Permissions', 'btn'],
			coumnsWithoutSorting: [2],
			customCells: [
				{ index: 2, celltype: 'chips' },
				{ index: 3, celltype: 'button-nolabel' },
			],
		};
		let dataSet = [];
		try {
			if (apiData) {
				apiData.map((role) => {
					return (dataSet = [
						...dataSet,
						{
							icons: [],
							id: role.id,
							values: [
								[role.id],
								[role.name],

								[role.permissions.map((permission) => permission.summary)],

								[
									['edit', 'primary'],
									['delete', 'secondary', 'Are you sure you want to delete this role?'],
									['duplicate', 'primary'],
								],
							],
						},
					]);
				});
				roles = { ...roles, dataSet };
				setState({ ...state, roles });
			}
		} catch (e) {
			console.log('error role list');
		}
	};

	useEffect(() => {
		compileList(allRoles);
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [allRoles]);

	useEffect(() => {
		clearSelectedRoleFn();
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleBtnClick = async (buttonType, buttonRow) => {
		if (buttonType[0] === 'edit') {
			const selectedRole = allRoles?.find((el) => el.id === buttonRow.id);
			setCurrentRoleFn(selectedRole);

			History.push('/account/roles/edit');
		} else if (buttonType[0] === 'delete') {
			deleteRoleFn(buttonRow.id);
		} else if (buttonType[0] === 'duplicate') {
			const bool = await duplicateRoleFn(buttonRow.id);
			if (bool) History.push('/account/roles/edit');
		}
	};
	const handleAddNewRoleBtnClick = async () => {
		const bool = await addRoleFn();
		if (bool) History.push('/account/roles/edit');
	};

	return (
		<PageWrapper>
			<ContentSection disableButton title='Role List'>
				<div
					style={{
						width: '100%',
						display: 'flex',
						justifyContent: 'space-between',
						padding: '0 5px',
					}}
				>
					<Button
						variant='contained'
						color='primary'
						//disabled
						onClick={handleAddNewRoleBtnClick}
					>
						add new role
					</Button>
				</div>

				<TableList
					data={state.roles}
					callBack={handleBtnClick}
					initialOrderBy='id '
					initialOrder='asc'
				/>
			</ContentSection>
		</PageWrapper>
	);
}
