import React, { useEffect } from 'react';
import { Tabs, Tab, Typography } from '@material-ui/core';
import Style from './navTabs.module.scss';
//import {  } from '../../../data/mockData';

import { Link } from 'react-router-dom';

import { Grid, Chip } from '@material-ui/core';

//TODO: figureout why is it dismounts/rerenders up to the root rout component, why during this rerender fonts did not import correctly (fallsback to the default font).
//TODO: prop forwarding debug

//Not using router before the fix

export function TabLink(props) {
	const { className, onClick, link, children, label } = props;

	const CustomLink = React.forwardRef((props, ref) => (
		<Tab component={Link} ref={ref} to={props.to} {...props} />
	));

	if (!link || typeof link !== 'string') {
		return (
			<Tab
				button
				className={className}
				children={children}
				onClick={onClick}
				label={label}
			/>
		);
	}

	// Return a LitItem with a link component
	return (
		<Tab
			button
			className={className}
			children={children}
			component={CustomLink}
			to={link}
			label={label}
			//selected={selected}
		/>
	);
}

export function NavTabs(props) {
	const {
		items = [],
		secondary = false,
		elRef = null,
		viewIndex = false,
		defaultValue = 0,
		disableSwitch = false,
	} = props;
	const [value, setValue] = React.useState(defaultValue);
	const handleChange = (event, newValue) => {
		if (disableSwitch) {
			setValue(newValue);
		}
		props.changeView(newValue);
	};

	// useEffect(() => {
	// 	tabsActions.current.updateIndicator();
	// }, [editMode]);

	useEffect(() => {
		if (!disableSwitch) {
			setValue(viewIndex);
			props.changeView(viewIndex);
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [viewIndex]);

	return (
		<Tabs
			//textColor='primary'
			classes={{ root: Style[secondary ? 'navTabs-root-Alt' : 'navTabs-root'] }}
			onChange={handleChange}
			value={value}
			action={elRef}
		>
			{items.map((item, itemIndex) => {
				return secondary ? (
					<Tab
						key={itemIndex}
						label={
							<Grid container direction='row' justify='center' alignItems='center'>
								<Grid item style={{ marginRight: 5 }}>
									<Typography variant='h4'>{item.label}</Typography>
								</Grid>
								<Grid item>
									<Chip
										label={item.chip}
										color={value === itemIndex ? 'primary' : 'default'}
										size='small'
										//	className={classes.root}
									/>
								</Grid>
							</Grid>
						}
						disableRipple
					/>
				) : (
					<Tab key={itemIndex} label={item.label} to={item.link} />
				);
			})}
		</Tabs>
	);
}
