import React from 'react';
import Highcharts from 'highcharts';

export class PieChart extends React.Component {
	container = React.createRef();
	chart = null;

	componentDidMount() {
		this.chart = Highcharts.chart(this.container.current, {
			chart: {
				plotBackgroundColor: null,
				plotBorderWidth: null,
				plotShadow: false,
				type: 'pie',
				backgroundColor: 'transparent',
			},

			title: {
				text: '',
				enabled: false,
			},
			legend: {
				align: 'right',
				verticalAlign: 'middle',
				// margin: 20,
				// padding: -10,
				//width: 200,
				layout: 'vertical',
				itemStyle: { color: '#fff' },
				dashStyle: 'dot',
				marker: {
					symbol: 'triangle',
				},
			},
			tooltip: {
				pointFormat: '<b>{point.percentage:.1f}%</b>',
			},
			accessibility: {
				point: {
					valueSuffix: '%',
				},
			},
			plotOptions: {
				pie: {
					allowPointSelect: true,
					cursor: 'pointer',
					dataLabels: {
						enabled: false,
					},
					showInLegend: true,
					marker: {
						symbol: 'square',
					},
				},
			},
			series: [
				{
					name: 'Channels',
					colorByPoint: true,
					data: [
						{
							name: 'Facebook',
							y: 61.41,
							sliced: true,
							selected: true,
							color: '#475A96',
						},
						{
							name: 'Zillow',
							y: 18.89,
							color: '#046AFF',
						},
						{
							name: 'Google Adwords',
							y: 10.85,
							color: '#fff',
						},
						{
							name: 'Youtube',
							y: 4.67,
							color: '#FF0000',
						},
						{
							name: 'Twitter',
							y: 4.18,
							color: '#55ACEE',
						},
					],
				},
			],
			credits: {
				enabled: false,
			},
		});
	}

	render() {
		return (
			<>
				<div
					ref={this.container}
					style={{
						//width: '100%',
						height: 272,
					}}
				/>
			</>
		);
	}
}
