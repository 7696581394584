import React from 'react';
import { Grid, Card, CardContent } from '@material-ui/core';
import Style from './card.module.scss';
import { makeStyles } from '@material-ui/core/styles';

export function CardMUI(props) {
	const {
		elevation,
		fullHeight,
		fullWidth,

		noPadding = false,
		customClasses = false,
	} = props;
	const classes = useStyles();

	return (
		<Card
			//style={{ borderradius: 10 }}
			//className={props.customClass}

			className={props.customClass}
			elevation={elevation !== undefined ? elevation : 1}
			classes={
				customClasses
					? customClasses
					: { root: fullHeight ? Style['rooth'] : fullWidth ? Style['rootw'] : null }
				//{ root: classes.cardRoot2 }
			}
		>
			<CardContent classes={{ root: noPadding ? classes.cardRoot : Style.root }}>
				<Grid
					container
					direction='column'
					justify='space-between'
					alignItems='center'
				>
					{props.children}
				</Grid>
			</CardContent>
		</Card>
	);
}

const useStyles = makeStyles((theme) => ({
	cardRoot: { padding: 0, paddingBottom: '0 !important' },
	cardRoot2: { backgroundColor: 'red' },
}));
