/***
 *
 *   AUTH LAYOUT
 *   Layout for the signup/signin pages
 *
 **********/

import React from 'react';

import Style from './auth.module.scss';

import { ThemeProvider } from '@material-ui/core/styles';

import { darkTheme } from 'components/global/material';
import { Link as RouterLink } from 'react-router-dom';
import { HeaderMUIGen } from 'components/lib';
import { CssBaseline, Button, ButtonGroup } from '@material-ui/core';
export function AuthLayoutMUI(props) {
	return (
		<>
			<ThemeProvider theme={darkTheme}>
				<CssBaseline />
				<main className={Style.auth}>
					<HeaderMUIGen title=' ' type='largeHeader-nosidebar'>
						<ButtonGroup
							color='primary'
							aria-label='text primary button group'
							size='large'
						>
							{/* <Button component={RouterLink} to='/signup'>
								Sign Up
							</Button> */}
							<Button component={RouterLink} to='/signin'>
								Sign In
							</Button>
						</ButtonGroup>
					</HeaderMUIGen>
					{<props.children {...props.data} />}
					{/* <Footer /> */}
				</main>
			</ThemeProvider>
		</>
	);
}
