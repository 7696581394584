/***
 *
 *   Member page
 *   Manage personal page of your member
 *
 **********/

import React, { useEffect, useState } from 'react';

import {
	PageWrapper,
	randPwd,
	useMemberInfo,
	History,
	useRoleItems,
	Loader,
	Wrapper,
} from 'components/lib';

import {
	Grid,
	Button,
	FormControl,
	Chip,
	InputLabel,
	Input,
	Select,
	MenuItem,
	Typography,
	Divider,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import { LoginInfo } from 'views/userProfile/profileCards/loginInfo';
import { ProfileInfo } from 'views/userProfile/profileCards/profileInfo';
import { ProfilePicture } from 'views/userProfile/profileCards/profilePicture';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
	anchorOrigin: {
		vertical: 'bottom',
		horizontal: 'left',
	},
	transformOrigin: {
		vertical: 'top',
		horizontal: 'left',
	},
	getContentAnchorEl: null,
};

export function MemberPage(props) {
	const {
		memberProfile,
		profileRequested,
		updateMemberRolesFn,
	} = useMemberInfo();
	const { allRoles } = useRoleItems();

	const classes = useStyles();
	const [state, setState] = useState({
		rolesChips: [],
		roleLabel: '',
		selectedRole: false,
		loader: false,
		msg: false,
	});

	useEffect(() => {
		if (!profileRequested) History.push('/users_managment/members');

		let rolesChips = [];
		//if (curentRole === false) History.push('/account/roles');
		if (memberProfile) {
			if (memberProfile && memberProfile?.roles?.length > 0) {
				const roles = [...memberProfile.roles];
				rolesChips = roles.map((chip) => chip.name);
			}

			setState((previousState) => {
				return {
					...previousState,
					rolesChips,
				};
			});
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [memberProfile]);

	const sortRoles = (roles) => {
		if (!roles) return [];
		try {
			//sort names
			roles.sort((a, b) => {
				//check what type of array we got
				const bool = typeof roles[0] === 'object' ? true : false;
				//grab first character of name
				const A = bool ? a.name.charAt(0).toLowerCase() : a.charAt(0).toLowerCase();
				const B = bool ? b.name.charAt(0).toLowerCase() : b.charAt(0).toLowerCase();
				//compare
				if (A < B) {
					return -1;
				}
				if (A > B) {
					return 1;
				}
				// names must be equal
				return 0;
			});

			return roles;
		} catch (error) {
			return roles;
		}
	};

	///////////////

	const handleChange = (event) => {
		setState((previousState) => {
			return {
				...previousState,
				rolesChips: event.target.value,
			};
		});
	};

	const compileActualRolesArr = () => {
		//eslint-disable-next-line array-callback-return
		const filteredRoles = allRoles.filter((role) => {
			const bool = state.rolesChips.includes(role.name);
			if (bool) return role;
		});

		return filteredRoles;
	};
	const handleSaveBtnClick = () => {
		//return false;

		const roles = compileActualRolesArr();

		const updateData = {
			id: memberProfile.id,
			roles,
		};

		//
		setState((previousState) => {
			return {
				...previousState,
				loader: true,
			};
		});

		updateMemberRolesFn(updateData).then((res) => {
			setState((previousState) => {
				return {
					...previousState,
					loader: false,
					msg: res ? 'roles is updated' : 'this role is not saved due some error',
				};
			});
			return res;
		});
	};
	const handleDelete = (chip) => {
		if (state.rolesChips?.length > 0) {
			const newChips = [...state.rolesChips];
			const index = newChips.indexOf(chip);

			if (index >= 0) {
				newChips.splice(index, 1);
			}
			setState((previousState) => {
				return {
					...previousState,
					rolesChips: newChips,
				};
			});
		}
	};

	////////////
	useEffect(() => {
		if (!profileRequested) History.push('/users_managment/members');
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<PageWrapper>
			<Divider style={{ marginBottom: '3em', height: 0 }} />
			<Grid container direction='column' justify='flex-start' alignItems='center'>
				<Grid
					item
					container
					direction='row'
					justify='center'
					alignItems='center'
					spacing={2}
				>
					<ProfileInfo userData={memberProfile} isMembmer={true} />
					<LoginInfo randPwd={randPwd} userData={memberProfile} isMembmer={true} />
					<ProfilePicture userData={memberProfile} isMembmer={true} />
				</Grid>
				<Grid item style={{ width: '75%' }}>
					<Wrapper>
						<div style={{ padding: '0px 10px', paddingBottom: '10px' }}>
							<Grid
								item
								container
								direction='row'
								alignItems='stretch'
								style={{ marginTop: 20, paddingTop: '10px' }}
							>
								<FormControl style={{ minWidth: 200, width: '100%' }}>
									<InputLabel
										id='edit-role-permissions-label'
										style={{ fontSize: 20 }}
										shrink
									>
										Member Roles
									</InputLabel>
									<Select
										classes={{ selectMenu: classes.selectMenu }}
										multiple
										value={state.rolesChips}
										onChange={handleChange}
										input={<Input id='edit-role-permissions-chip' />}
										renderValue={(selected) => (
											<div>
												{sortRoles(selected).map((value) => (
													<Chip
														key={value + Math.random()}
														label={value}
														color='primary'
														style={{
															fontSize: '12px',
															height: '2em',
															fontFamily: 'AvenirRoman',
															lineHeight: 'normal',
															marginRight: 5,
														}}
														onDelete={() => handleDelete(value)}
														onMouseDown={(event) => {
															event.preventDefault();
															event.stopPropagation();
														}}
													/>
												))}
											</div>
										)}
										MenuProps={MenuProps}
									>
										{sortRoles(allRoles).map((chip) => (
											<MenuItem key={chip.id ? chip.id : Math.random()} value={chip.name}>
												{chip.name}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>

							<Grid
								item
								container
								justify='flex-start'
								spacing={1}
								style={{ marginTop: 5 }}
							>
								<Grid item>
									<Button
										variant='contained'
										color='primary'
										//disabled
										onClick={handleSaveBtnClick}
									>
										update roles
									</Button>
								</Grid>
							</Grid>
							{state.loader && <Loader />}
							{state.msg && <Typography>{state.msg}</Typography>}
						</div>
					</Wrapper>
				</Grid>
			</Grid>
		</PageWrapper>
	);
}

const useStyles = makeStyles((theme) => ({
	selectMenu: {
		whiteSpace: 'pre-wrap',
		textOverflow: 'ellipsis',
		lineHeight: '30px',
		minHeight: '1.1876em',
	},
	TextFieldresize: {
		'& .MuiInput-input': {
			fontSize: 16,
			marginTop: 6,
			paddingLeft: 2,
		},
	},
	labelTypography: { fontSize: 20, marginBottom: 13 },
}));
