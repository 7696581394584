/**
 * Context provider to manage currently selected member from the list of members in order to make unique api call for it and generate custom member profile page
 */

import React, { useContext, useState, useEffect } from 'react';
import { apiAxiosCall, History } from 'components/lib';

const MemberContext = React.createContext({
	memberId: false,
	memberProfile: false,
	profileRequested: false,
	listOfMembers: false,
	editMember: () => {},
	updateMember: () => {},
	updateMemberRolesFn: () => {},
	deleteMember: () => {},
	reqListOfMembers: () => {},
});

export const useMemberInfo = () => {
	return useContext(MemberContext);
};

export const MemberProvider = (props) => {
	const [state, setState] = useState({
		memberProfile: false,
		profileRequested: false,
		profileId: false,
	});

	const reqListOfMembers = async () => {
		try {
			const res = await apiAxiosCall(`/api/user/`);
			if (res.err) return false;

			setState((previousState) => {
				return {
					...previousState,
					listOfMembers: res.data,
				};
			});
			return res;
		} catch (e) {
			console.log('error in member context GET');
			return false;
		}
	};

	const requestUser = async (id) => {
		if (!id) return false;
		setState((previousState) => {
			return {
				...previousState,
				profileRequested: true,
				profileId: id,
			};
		});
		try {
			const res = await apiAxiosCall(`/api/user/${id}`);
			if (res.err) return false;
			return res;
		} catch (e) {
			console.log('error in member context GET');
			return false;
		}
	};
	const requestRemovalOfUser = async (id) => {
		if (!id) return false;

		try {
			const res = await apiAxiosCall(`/api/user/${id}`, 'DELETE');
			if (res.err) return false;
			return res;
		} catch (e) {
			console.log('error in member context delete');
			return false;
		}
	};
	const updateMember = () => {
		requestUser(state.profileId).then(async (res) => {
			if (!res.err && res.data) {
				await setState((previousState) => {
					return {
						...previousState,
						memberProfile: res.data,
					};
				});
			}
		});
	};
	const editMember = (id) => {
		requestUser(id).then(async (res) => {
			if (!res.err && res.data) {
				await setState((previousState) => {
					return {
						...previousState,
						memberProfile: res.data,
					};
				});
			}
		});
	};

	const updateMemberRolesFn = async (memberData) => {
		if (!memberData) return false;

		try {
			const res = await apiAxiosCall(`/api/user/`, 'PUT', memberData);

			if (res.err) return false;

			return res;
		} catch (e) {
			console.log('error in member context put');
			return false;
		}
	};

	const deleteMember = async (id) => {
		await requestRemovalOfUser(id);
		reqListOfMembers();
	};

	useEffect(() => {
		if (state.profileRequested && state.memberProfile)
			History.push('/users_managment/members/personal');
	}, [state.memberProfile]);

	// useEffect(() => {
	// 	reqListOfMembers();
	// }, []);

	return (
		<MemberContext.Provider
			value={{
				memberProfile: state.memberProfile,
				profileRequested: state.profileRequested,
				listOfMembers: state.listOfMembers,
				editMember,
				updateMember,
				updateMemberRolesFn,
				deleteMember,
				reqListOfMembers,
			}}
		>
			{props.children}
		</MemberContext.Provider>
	);
};
