// import { Dashboard } from 'views/dashboard/dashboard';

export default [
	//DEMO ROUTES
	// {
	// 	path: '/overview',
	// 	view: ManagerDashboard,
	// 	layout: 'appMUI',
	// 	//permission: 'user',
	// 	title: 'Dashboard Overview',
	// },
];
