/**
 * Context provider to manage currently selected agent from the list of agents in order to make unique api call for it and generate custom agent page
 */

import React, { useContext, useState } from 'react';

const AgentContext = React.createContext({
	agentId: false,
	agentName: false,
	agentType: false,
	updateAgent: () => {},
});

export const useAgentInfo = () => {
	return useContext(AgentContext);
};

export const AgentProvider = (props) => {
	const [agentState, setAgentState] = useState({
		agentName: false,
		agentId: false,
		agentType: false,
	});
	const updateAgent = (agentUpdate) => {
		setAgentState({ ...agentState, ...agentUpdate });
	};
	return (
		<AgentContext.Provider
			value={{
				agentId: agentState.agentId,
				agentName: agentState.agentName,
				agentType: agentState.agentType,
				updateAgent,
			}}
		>
			{props.children}
		</AgentContext.Provider>
	);
};
