/***
 *
 *   INVITES
 *   Invite users and monitor current invites status
 *
 **********/

import React, { useEffect, useState, useContext } from 'react';
import {
	ContentSection,
	Form,
	CardMUI as Card,
	TableList,
} from 'components/lib';
import { Grid, CircularProgress } from '@material-ui/core';
import { useAPI, ViewContext } from 'components/lib';
import Axios from 'axios';

export function InvitesPage(props) {
	const [state, setState] = useState({
		loading: true,
		roles: null,
		invites: null,
	});
	let requestRoles = useAPI('/api/role');
	let requestInvites = useAPI('/api/invite');
	const context = useContext(ViewContext);

	async function apiCall(method, url) {
		let res = await Axios({
			method: method,
			url: process.env.REACT_APP_API_URI + url,
		});
		return res;
	}

	useEffect(() => {
		let roles = [];
		if (requestRoles.data) {
			requestRoles.data.map((role, roleIndex) => {
				roles = [...roles, { value: role.id, label: role.name, role: role }];
			});
			setState({ ...state, loading: false, roles: roles, update: false });
		}
	}, [requestRoles]);

	const formCallback = async (clbk) => {
		let requestNewInvites = await apiCall('get', '/api/invite');
		compileList({ data: requestNewInvites.data });
	};

	const handleBtnClick = async (buttonType, buttonRow) => {
		if (buttonType[0] === 'cancel') {
			let deleteInvite = await apiCall('delete', `/api/invite/${buttonRow.id}`);

			let requestNewInvites = await apiCall('get', '/api/invite');
			compileList({ data: requestNewInvites.data });
		}
		if (buttonType[0] === 'resend') {
			let resendInvite = await apiCall(
				'get',
				`/api/invite/resend/${buttonRow.id}`
			);
			if (resendInvite.data.message)
				context.notification.show(resendInvite.data.message, '', true);
		}
	};
	const getDate = (str) => {
		let newDate = null;
		return (newDate = [
			str.split('T')[0],
			str.split('T')[1].split('Z')[0].split('.')[0],
		]);
	};

	const compileList = (apiData) => {
		setState({ ...state, invites: false });
		let invites = {
			headerName: '',
			labels: ['Email Adress', 'Role', 'date send', 'btn'],
			iconInColumns: [],
			customCells: [{ index: 3, celltype: 'button-nolabel' }],
		};
		let dataSet = [];

		if (apiData.data) {
			apiData.data.map((invite, inviteIndex) => {
				dataSet = [
					...dataSet,
					{
						icons: [],
						id: invite.id,
						values: [
							[invite.email],
							[null, invite.roles[0]?.name ? invite.roles[0].name : 'failed'],
							[getDate(invite.createdAt)[0], getDate(invite.createdAt)[1]],
							[
								['resend', 'primary'],
								['cancel', 'secondary', 'Are you sure you want to cancel this invite?'],
							],
						],
					},
				];
			});
			invites = { ...invites, dataSet };
			setState({ ...state, invites });
		}
	};

	useEffect(() => {
		compileList(requestInvites);
	}, [requestInvites]);

	return (
		<>
			<ContentSection title='Send Invitation' type='home' align>
				<Card restrictWidth center fullHeight>
					{!state.loading ? (
						<Form
							data={{
								email: {
									label: 'Email',
									type: 'email',
									required: true,
								},
								inv_role: {
									label: 'Agent Role',
									type: 'select',
									options: state.roles,
									//default: state.roles[0].id,
									required: true,
								},
							}}
							url='/api/invite'
							method='POST'
							buttonText='Send'
							customSchema={{
								email: null,
								roles: [],
							}}
							callback={formCallback}
						/>
					) : (
						<CircularProgress />
					)}
				</Card>
			</ContentSection>

			<ContentSection disableButton title='Pending Invites'>
				<TableList data={state.invites} callBack={handleBtnClick} />
			</ContentSection>
			{/* </Grid> */}
		</>
	);
}
