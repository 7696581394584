/***
 *
 *   HOMEPAGE
 *   Homepage template with features, testimonials and CTA
 *
 **********/

import React, { useState, useEffect } from 'react';

import { Typography, Grid, Button } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { useNavItems } from 'components/lib';

export function ErrorPage(props) {
	let token = JSON.parse(localStorage.getItem('accessToken'));
	let { menuItems } = useNavItems();
	const [linkTo, setLinkTo] = useState('/');

	useEffect(() => {
		if (menuItems) {
			const dashboardMenu = menuItems.filter(
				(obj) => obj.name === 'Dashboard'
			)?.[0];
			const newLink =
				dashboardMenu?.items?.length > 0 ? dashboardMenu.items[0].link : '/profile';
			setLinkTo(newLink);
		}
	}, [menuItems]);

	return (
		<Grid
			container
			direction='column'
			justify='flex-start'
			style={{
				paddingTop: '5em',

				minHeight: '100vh',
				alignItems: 'center',
			}}
		>
			<Typography variant='h2' align='center' style={{ padding: '3em' }}>
				Looks like the page you're looking for doesn't exist.
			</Typography>
			{token && (
				<Button
					variant='contained'
					color='primary'
					size='large'
					component={RouterLink}
					to={linkTo}
				>
					Go back to the App
				</Button>
			)}
		</Grid>
	);
}
