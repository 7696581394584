import { useMemo } from 'react';
import { useAPI } from 'components/lib';

import Axios from 'axios';

import '@formatjs/intl-numberformat/polyfill';
import '@formatjs/intl-numberformat/locale-data/en'; // locale-data for en

export const useComponentWillMount = (func) => {
	useMemo(func, []);
};

export const useCheckAuth = () => {
	useAPI('/auth/check');
};

export const getDate = (str, justDate = false, unix = false) => {
	let newDate = null;
	if (!str || str === '-') return '-';
	if (unix) {
		newDate = new Date(str * 1000);
	} else {
		newDate = new Date(str);
	}

	let month = [
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'May',
		'Jun',
		'Jul',
		'Aug',
		'Sep',
		'Oct',
		'Nov',
		'Dec',
	][newDate.getMonth()];
	if (justDate && newDate) return `${(newDate.getTime() / 1000) | 0}`;
	return (newDate = [
		newDate
			? `${newDate.getDate()} ${month}, ${newDate.getFullYear()} ${newDate.toLocaleTimeString()}`
			: '',
	]);
};

export const currencyFormatter = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD',
	minimumFractionDigits: 0,
	maximumFractionDigits: 0,
});

export const currencyFormatterCompact = new Intl.NumberFormat('en-US', {
	notation: 'compact',
	compactDisplay: 'short',
	style: 'currency',
	currency: 'USD',
});

//rand
export const rand = () => {
	let r = '';
	let l = Math.floor(Math.random() * 14) + 3;
	for (let i = 0; i < l; i++) {
		r += '*';
	}
	return r;
};

export const randPwd = rand();

export const ConditionalWrapper = ({ condition, wrapper, children }) =>
	condition ? wrapper(children) : children;

export function percIncrease(newNum, oldNum) {
	if (typeof newNum !== 'number' || typeof oldNum !== 'number') return false;
	let percent;
	if (newNum === oldNum || (newNum === 0 && oldNum === 0)) return 0;
	if (oldNum === 0) return 100;
	if (newNum === 0) return -100;
	percent = ((newNum - oldNum) / oldNum) * 100;

	// if(percent>100) return 100;
	// if(percent<-100) return -100;

	return Math.round((percent + Number.EPSILON) * 100) / 100;
}

export const dataValidator = (val, type) => {
	if (val === null || val === '-' || val === undefined) return false;
	if (type === 'name-short') {
		let { firstName, lastName } = val;

		firstName =
			firstName === null || firstName === '-' || firstName === undefined
				? '-'
				: firstName;
		lastName =
			lastName === null || lastName === '-' || lastName === undefined
				? '-'
				: lastName.charAt(0).toUpperCase() + '.';

		return `${firstName} ${lastName}`;
	}

	return val;
}; // simple data validator for the lists to check on missing data

export async function apiAxiosCall(url, method, data) {
	console.log(data);
	try {
		if (!url) {
			return false;
		}
		let res = await Axios({
			method: method || 'get',
			url: process.env.REACT_APP_API_URI + url,
			data,
		});

		return {
			err: false,
			data: res.data,
		};
	} catch (err) {
		return {
			err: true,
			data: err,
		};
	}
}
