import React from 'react';

import {
	FormControl,
	Select as SelectMUI,
	MenuItem,
	Divider,
	Grid,
	Typography,
} from '@material-ui/core';
import Style from './select.module.scss';

//TODO label position left-right top-bottom
//props: label

const menuProps = {
	classes: {
		paper: Style['dropdown-menu'],
	},
};

export const Select = function (props) {
	const { selectLabel } = props;

	return (
		<Grid
			container
			item
			direction='row'
			justify='center'
			alignItems='center'
			style={{ width: 'fit-content' }}
			classes={{
				root: Style['grid-container-root'],
			}}
		>
			{selectLabel && <Typography>compare to</Typography>}
			<FormControl
				classes={{
					root: Style['form-control-root'],
				}}
				//className={(classes.formControl, Style.formControlC)}
			>
				<SelectMUI defaultValue={1} id='grouped-select' MenuProps={menuProps}>
					<MenuItem
						value={1}
						classes={{
							//	root: Style['dropdown-menu'],
							root: Style['ropdown-menu'],
						}}
					>
						last month
					</MenuItem>
					<MenuItem value={2} classes={{ selected: Style['selected-item'] }}>
						July 2019
					</MenuItem>
					<Divider />
					<MenuItem value={3} classes={{ selected: Style['selected-item'] }}>
						This Year
					</MenuItem>
					<MenuItem value={4} classes={{ selected: Style['selected-item'] }}>
						Last Year
					</MenuItem>
					<Divider />
					<MenuItem value='' classes={{ selected: Style['selected-item'] }}>
						None
					</MenuItem>
				</SelectMUI>
			</FormControl>
		</Grid>
	);
};
