/***
 *
 *   RESET PASSWORD
 *   User can set a new password using the token
 *
 **********/

import React from 'react';

import { Form, ContentSection, CardMUI as Card, Animate } from 'components/lib';
import { Grid } from '@material-ui/core';

export function ResetPassword(props) {
	// context
	// const context = useContext(AuthContext);

	// const [state, setState] = useState({
	// 	loading: true,
	// 	invite: null,
	// });

	const url = window.location.href;
	const resetId = url.substr(url.lastIndexOf('/') + 1);

	const redirect = () => (window.location = '/signin');

	return (
		<Animate type='pop'>
			<Grid
				container
				direction='column'
				justify='flex-start'
				style={{
					padding: '4em 0',
					alignItems: 'center',
					minHeight: '100vh',
				}}
			>
				<ContentSection type='home' title='Reset Password'>
					<Card>
						<Form
							data={{
								// jwt: {
								// 	type: 'hidden',
								// 	value: token,
								// },
								password: {
									label: 'New Password',
									type: 'password',
									required: true,
									compareTo: true,
								},
								repeatedPassword: {
									label: 'Confirm Password',
									type: 'password',
									required: true,
									compare: true,
									errorMessage: 'passwords does not match',
								},
							}}
							url={`/auth/newPassword/${resetId}`}
							method='POST'
							buttonText='Set New Password'
							callback={() => setTimeout(redirect, 1100)}
						/>
					</Card>
				</ContentSection>
			</Grid>
		</Animate>
	);
}
