// import { SetupWelcome } from 'views/setup/welcome';
// import { SetupDatabase } from 'views/setup/database';
// import { SetupStripe } from 'views/setup/stripe';
// import { SetupMailgun } from 'views/setup/mailgun';
// import { SetupRestart } from 'views/setup/restart';
// import { SetupAccount } from 'views/setup/account';
// import { SetupFinished } from 'views/setup/finished';

export default [
	// {
	// 	path: '/setup',
	// 	view: SetupWelcome,
	// 	layout: 'setup',
	// 	title: 'Welcome to Gravity',
	// },
	// {
	// 	path: '/setup/database',
	// 	view: SetupDatabase,
	// 	layout: 'setup',
	// 	title: 'Connect Your Database',
	// },
	// {
	// 	path: '/setup/stripe',
	// 	view: SetupStripe,
	// 	layout: 'setup',
	// 	title: 'Connect Your Stripe Account',
	// },
	// {
	// 	path: '/setup/mailgun',
	// 	view: SetupMailgun,
	// 	layout: 'setup',
	// 	title: 'Connect Your Mailgun Account',
	// },
	// {
	// 	path: '/setup/restart',
	// 	view: SetupRestart,
	// 	layout: 'setup',
	// 	title: 'Restart Server',
	// },
	// {
	// 	path: '/setup/account',
	// 	view: SetupAccount,
	// 	layout: 'setup',
	// 	title: 'Create Your Master Account',
	// },
	// {
	// 	path: '/setup/finished',
	// 	view: SetupFinished,
	// 	layout: 'setup',
	// 	title: 'Setup Complete',
	// },
];
