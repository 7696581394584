import React from 'react';
import { Link } from 'react-router-dom';
import { ListItem } from '@material-ui/core';

export function ListItemLink(props) {
	const {
		className,
		onClick,
		link,
		children,
		to,
		//  , NavLinkProps
	} = props;

	const CustomLink = React.useMemo(
		() =>
			React.forwardRef((NavLinkProps, ref) => (
				<Link ref={ref} to={to} {...NavLinkProps} />
			)),
		[to]
	);
	if (!link || typeof link !== 'string') {
		return (
			<ListItem
				button
				className={className}
				children={children}
				onClick={onClick}
			/>
		);
	}

	// Return a LitItem with a link component
	return (
		<ListItem
			button
			className={className}
			children={children}
			component={CustomLink}
			to={link}
			onClick={onClick}
		/>
	);
}
