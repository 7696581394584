import React from 'react';
import { Grid, CircularProgress } from '@material-ui/core';
import { ConditionalWrapper } from 'components/lib';
export const Loader = (props) => {
	const { wrapped = false, customStyle = { height: '100%' } } = props;
	return (
		<ConditionalWrapper
			condition={wrapped}
			wrapper={(children) => (
				<Grid
					item
					container
					justify='center'
					alignItems='center'
					style={customStyle}
				>
					{children}
				</Grid>
			)}
		>
			<CircularProgress />
		</ConditionalWrapper>
	);
};
