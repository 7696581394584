/***
 *
 *   PROFILE
 *   Update the user profile
 *
 **********/

import React from 'react';

import { useCheckAuth, useAPI, PageWrapper } from 'components/lib';

import { ProfileWrapper } from './profileWrapper';

export function Profile(props) {
	useCheckAuth();
	useAPI('/auth/check');

	return (
		<PageWrapper>
			<ProfileWrapper />
		</PageWrapper>
	);
}
