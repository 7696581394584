/***
 *
 *   Company Account Settings
 *   Update company logo and info
 *
 **********/

import React, { useEffect, useState } from 'react';

import {
	PageWrapper,
	apiAxiosCall,
	ContentSection,
	SwitchBtns,
	useNavItems,
} from 'components/lib';

import { Grid, Divider, Typography } from '@material-ui/core';

import { ProfileInfo } from 'views/userProfile/profileCards/profileInfo';
import { ProfilePicture } from 'views/userProfile/profileCards/profilePicture';

export function Settings(props) {
	const [state, setState] = useState(false);
	let { setUpdateLogo, selectedLogo } = useNavItems();

	const requestCompanyInfo = async () => {
		try {
			const res = await apiAxiosCall(`/api/organization`);

			if (res.err) return false;
			return res;
		} catch (e) {
			console.log('error in api/organization');
			return false;
		}
	};

	useEffect(() => {
		requestCompanyInfo().then((res) => {
			if (!res.error) {
				setState(res.data);
			}
		});
	}, []);

	const handleTimeSelector = (index, val) => {
		let indx = parseInt(index);
		switch (indx) {
			case 0:
				setUpdateLogo(val);
				break;
			default:
				break;
		}
	};

	return (
		<PageWrapper>
			<Divider style={{ marginBottom: '1.5em', height: 0 }} />
			<ContentSection
				disableButton
				//title=' '
				headerEl={
					selectedLogo ? (
						<Grid container direction='column' justify='center' alignItems='center'>
							<Grid item>
								<Typography variant='h6'>Display logo as:</Typography>
							</Grid>
							<Grid item>
								<SwitchBtns
									clb={handleTimeSelector}
									indx='0'
									defVal='name'
									buttonsMapping={[
										{ label: 'name', val: 'name' },
										{ label: 'initials', val: 'initials' },
										{ label: 'picture', val: 'picture' },
									]}
								/>
							</Grid>
						</Grid>
					) : (
						<div style={{ height: 57 }} />
					)
				}
			>
				<Grid
					container
					direction='row'
					justify='center'
					alignItems='center'
					spacing={2}
					//style={{ marginTop: '3em' }}
				>
					<ProfileInfo userData={state} isCompany />

					<ProfilePicture userData={state} isCompany />
				</Grid>
			</ContentSection>
		</PageWrapper>
	);
}
