//Bar Chart

import * as _ from 'lodash';
import { currencyFormatter } from 'components/lib';

export const barData = {
	categories: ['Leads', 'Calls', 'Appntmnts', 'Agrmnts', 'ESCROW', 'Closed'],

	series: [
		{
			name: 'FB',
			data: [8, 8, 4, 7, 2, 6],
			color: '#C361E5',
		},
		{
			name: 'Zillow',
			data: [10, 11, 14, 15, 5, 2],
			color: '#6179E5',
		},
		{
			name: 'Google',
			data: [10, 3, 13, 10, 5, 1],
			color: '#61E5CD',
		},
		{
			name: 'Other',
			data: [10, 4, 2, 2, 5, 1],
			color: '#E5B061',
		},
	],
};

export const barChartOptions = {
	chart: {
		type: 'column',
		backgroundColor: 'transparent',
	},

	legend: {
		align: 'right',
		verticalAlign: 'top',
		margin: 0,

		width: '30%',
		layout: 'horizontal',
		itemStyle: { color: '#fff' },
		dashStyle: 'dot',
	},
	title: {
		text: '',
		enabled: false,
	},
	xAxis: {
		labels: {
			style: {
				color: '#fff',
				fontSize: '1.2em',
				fontWeight: 900,
				fontFamily: 'AvenirHeavy, AvenirRoman',
			},
		},
		lineWidth: 0,
		categories: null,
	},

	yAxis: {
		labels: {
			enabled: false,
		},
		gridLineWidth: 0,
		allowDecimals: false,
		min: 0,
		title: {
			enabled: false,
		},
	},

	plotOptions: {
		column: {
			stacking: 'normal',
		},
		columnrange: {},
	},
	series: null,
	credits: {
		enabled: false,
	},
};

const data = _.times(12, (index) => ({
	x: index,
	y: _.random(-1, 1) * _.random(20),
}));
const data2 = _.times(12, (index) => ({
	x: index,
	y: _.random(-1, 1) * _.random(20),
}));
const data3 = _.times(12, (index) => ({
	x: index,
	y: _.random(-1, 1) * _.random(20),
}));
const data4 = _.times(12, (index) => ({
	x: index,
	y: _.random(-1, 1) * _.random(20),
}));
const CurrentDate = 7.7;

export const lineData = {
	title: {
		text: '',
		enabled: false,
	},
	chart: { backgroundColor: 'transparent' },
	yAxis: {
		labels: {
			formatter: function () {
				return Math.sign(this.value) === 1
					? `$${this.value}K`
					: Math.sign(this.value) === -1
					? `-$${this.value * -1}K`
					: `$${this.value}K`;
			},
			style: {
				color: '#8B8BA1',
			},
		},
		gridLineWidth: 0,
		title: {
			enabled: false,
		},
		plotBands: [
			{
				from: -6000000,
				to: 0,
				color: '#20262D',
			},
		],
	},
	xAxis: {
		categories: [
			'Jan',
			'Feb',
			'Mar',
			'Apr',
			'May',
			'Jun',
			'Jul',
			'Aug',
			'Sep',
			'Oct',
			'Nov',
			'Dec',
		],
		labels: {
			reserveSpace: false,
			step: 1,
		},

		title: { text: '' },
		tickWidth: 1,

		plotLines: [
			{
				color: '#8B8BA1',
				width: 3,
				value: CurrentDate, // var.
				zIndex: 1,
			},
		],
	},
	plotOptions: {
		series: {
			label: {
				connectorAllowed: false,
			},
			pointStart: 2010,
		},
	},
	legend: {
		align: 'right',
		verticalAlign: 'top',
		margin: 20,
		padding: -10,
		width: 400,
		layout: 'horizontal',
		itemStyle: { color: '#fff' },
		dashStyle: 'dot',
	},
	series: [
		{
			data,
			name: 'FB',
			color: '#C361E5',
			zoneAxis: 'x',
			zones: [
				{
					value: CurrentDate, //var
				},
				{
					dashStyle: 'dot',
				},
			],
		},
		{
			name: 'Zillow',
			color: '#6179E5',
			data: data2,
			zoneAxis: 'x',
			zones: [
				{
					value: CurrentDate, //var
				},
				{
					dashStyle: 'dot',
				},
			],
		},
		{
			data: data3,
			name: 'Google',
			color: '#61E5CD',
			zoneAxis: 'x',
			zones: [
				{
					value: CurrentDate, //var
				},
				{
					dashStyle: 'dot',
				},
			],
		},
		{
			data: data4,
			name: 'Other',
			color: '#E5B061',
			zoneAxis: 'x',
			zones: [
				{
					value: CurrentDate, //var
				},
				{
					dashStyle: 'dot',
				},
			],
		},
	],
	credits: {
		enabled: false,
	},
};

export const lineDataAlt = {
	title: {
		text: '',
		enabled: false,
	},
	chart: { backgroundColor: 'transparent', height: 140 },
	yAxis: {
		labels: {
			formatter: function () {
				return Math.sign(this.value) === 1
					? `$${this.value}K`
					: Math.sign(this.value) === -1
					? `-$${this.value * -1}K`
					: `$${this.value}K`;
			},
			style: {
				color: '#8B8BA1',
			},
		},
		gridLineWidth: 0,
		title: {
			enabled: false,
		},
	},
	xAxis: {
		categories: [
			'Jan',
			'Feb',
			'Mar',
			'Apr',
			'May',
			'Jun',
			'Jul',
			'Aug',
			'Sep',
			'Oct',
			'Nov',
			'Dec',
		],
		labels: {
			reserveSpace: false,
			step: 1,
		},

		title: { text: '' },
		tickWidth: 1,
	},

	legend: {
		enabled: false,
	},
	series: [
		{
			data,
			name: 'sales',
			color: '#6179E5',
			zoneAxis: 'x',
		},
	],
	credits: {
		enabled: false,
	},
};

export const lineDataMngTemplate = {
	title: {
		text: '',
		enabled: false,
	},
	chart: {
		backgroundColor: 'transparent',
		height: 240,
		//type: 'spline',
	},
	yAxis: {
		gridLineWidth: 0,
		lineWidth: 2,
		title: {
			enabled: false,
		},
		max: null,
		min: null,
		tickAmount: 5,
		lineColor: '#171C22',
		tickWidth: 1,
		tickColor: '#171C22',
		height: '97%',
		resize: {
			enabled: true,
		},
	},
	xAxis: {
		type: 'datetime',
		allowDecimals: false,
		lineColor: '#171C22',
		lineWidth: 2,
		tickWidth: 1,
		alignTicks: true,
		tickPositioner: function () {
			let xAxs = this.chart.xAxis[0];
			const ticks = [...xAxs.series[0].processedXData];
			//let a = false;
			// const nonColissionTicks = ticks.filter((i, index) => {
			// 	let check;
			// 	if (a) {
			// 		check = parseInt(`${i - a}`.substring(0, 2));
			// 	}
			// 	a = i;
			// 	console.log('check', check);
			// 	if (check > 24 || index === 0) return i;
			// });  //no need for this because of consistent data from the back-end. Leave it commented here just in case.
			//nonColissionTicks.info = this.tickPositions.info;
			//return nonColissionTicks;
			ticks.info = this.tickPositions.info;
			return ticks;
		},
		tickColor: '#171C22',
		crosshair: {
			enabled: true,
			color: '#171C22',
		},
		labels: {
			rotation: -55,
		},
	},
	tooltip: {
		style: {
			color: '#fff',
		},

		followTouchMove: false,
		followPointer: false,
		//split: true,
		crosshairs: true,
		shared: true,
		useHTML: true,
		backgroundColor: 'rgba(23, 28, 34, 0.9)',
		borderColor: 'transparent',
		borderRadius: 6,

		formatter: function () {
			const unixDate = new Date(this.x);
			const date = `${unixDate.toDateString()}`;
			let label = this.points.reduce(function (s, point) {
				return (
					s +
					`<div style="width:100%;"><span style="color:${
						point.series.color
					}">\u25CF</span> ${point.series.name}: ${currencyFormatter.format(
						point.y
					)}</div>`
				);
			}, `<div style="color:#8B8BA1;width:100%;text-align:center;margin:0;padding:0;">${date}</div>`);
			if (this.points.length > 1) {
				const labelSum = this.points.reduce(function (s, point) {
					return s.y + point.y;
				});

				const labelFooter = `<div style="height:2px;width:100%;background-color: #283038;padding:0;margin:3px 0;"></div><div style="color:#8B8BA1;width:100%;text-align:center;">${currencyFormatter.format(
					labelSum
				)}</div>`;
				label = label + labelFooter;
			}

			return label;
		},
	},

	legend: {
		align: 'right',
		verticalAlign: 'top',
		//margin: 20,
		//	padding: -10,
		//	width: 400,
		layout: 'horizontal',
		itemStyle: { color: '#fff' },
		dashStyle: 'dot',
	},
	series: [],

	credits: {
		enabled: false,
	},
	plotOptions: {
		spline: {
			lineWidth: 2,
			states: {
				hover: {
					lineWidth: 3,
				},
			},
			marker: {
				enabled: false,
			},
			pointInterval: 1000,
			pointStart: 0,
		},
	},
};

export const projectionLineChartTemplate = {
	title: {
		text: 'Sales Volume',
		floating: true,
		align: 'left',
		x: 3,
		y: 20,
		style: {
			color: '#fff',
			fontFamily: 'AvenirHeavy, AvenirRoman, serif',
		},
	},
	chart: {
		backgroundColor: 'transparent',
		height: 240,
		//type: 'spline',
		events: {
			load: function () {
				const chart = this;
				const xAxis = chart.xAxis[0];
				xAxis.addPlotBand({
					from: xAxis.tickPositions[0],
					to: xAxis.tickPositions[3],
					color: '#20262D',
				});
				xAxis.addPlotBand({
					from: xAxis.tickPositions[3],
					to: xAxis.tickPositions[6],
					color: '#171c2230',
				});

				xAxis.addPlotBand({
					from: xAxis.tickPositions[6],
					to: xAxis.tickPositions[9],
					color: '#20262D',
				});
				xAxis.addPlotBand({
					from: xAxis.tickPositions[9],
					to: xAxis.tickPositions[12],
					color: '#171c2230',
				});
			},
		},
	},
	yAxis: {
		gridLineWidth: 0,
		lineWidth: 2,
		title: {
			enabled: false,
		},
		max: null,
		min: null,
		tickAmount: 5,
		lineColor: '#171C22',
		tickWidth: 1,
		tickColor: '#171C22',
	},
	xAxis: {
		type: 'datetime',
		allowDecimals: false,
		lineColor: '#171C22',
		lineWidth: 2,
		tickWidth: 1,
		alignTicks: true,
		tickColor: '#171C22',
		crosshair: {
			enabled: true,
			color: '#171C22',
		},
		plotLines: [
			{
				color: '#8B8BA1',
				width: 3,
				value: CurrentDate, // var.
				zIndex: 1,
			},
		],
	},
	tooltip: {
		style: {
			color: '#fff',
		},
		followTouchMove: false,
		followPointer: false,
		//	split: true,
		crosshairs: true,
		shared: true,
		useHTML: true,
		backgroundColor: 'rgba(23, 28, 34, 0.9)',
		borderColor: 'transparent',
		borderRadius: 6,

		formatter: function () {
			const unixDate = new Date(this.x);
			const date = `${unixDate.toDateString()}`;
			let label = this.points.reduce(function (s, point) {
				return (
					s +
					`<div style="width:100%;"><span style="color:${
						point.series.color
					}">\u25CF</span> ${point.series.name}: ${currencyFormatter.format(
						point.y
					)}</div>`
				);
			}, `<div style="color:#8B8BA1;width:100%;text-align:center;margin:0;padding:0;">${date}</div>`);
			if (this.points.length > 1) {
				const labelSum = this.points.reduce(function (s, point) {
					return s.y + point.y;
				});

				const labelFooter = `<div style="height:2px;width:100%;background-color: #283038;padding:0;margin:3px 0;"></div><div style="color:#8B8BA1;width:100%;text-align:center;">${currencyFormatter.format(
					labelSum
				)}</div>`;
				label = label + labelFooter;
			}

			return label;
		},
	},
	legend: {
		align: 'right',
		verticalAlign: 'top',
		layout: 'horizontal',
		itemStyle: { color: '#fff' },
		dashStyle: 'dot',
	},
	series: [],
	credits: {
		enabled: false,
	},

	plotOptions: {
		spline: {
			lineWidth: 2,
			states: {
				hover: {
					lineWidth: 3,
				},
			},
			marker: {
				enabled: false,
			},
			pointInterval: 1000,
			pointStart: 0,
		},
	},
};
