import React from 'react';

import {
	TableHead,
	TableRow,
	TableCell,
	Icon,
	TableSortLabel,
} from '@material-ui/core';

import Style from './table.module.scss';
import { loadCSS } from 'fg-loadcss';

export const ListHeader = function (props) {
	const {
		headerData = [],
		data = [],
		onRequestSort,
		orderBy,
		order,
		topResults,
	} = props;

	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};
	React.useEffect(() => {
		const node = loadCSS(
			'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
			document.querySelector('#font-awesome-css')
		);

		return () => {
			node.parentNode.removeChild(node);
		};
	}, []);

	return (
		<TableHead
			classes={{ root: Style['tableHead-root'] }} //borderRadius='50%'
		>
			<TableRow>
				{!headerData
					? null
					: headerData.map((headCell, index) => {
							const headCellId = headCell.toLowerCase();
							return headCell.search('btn') !== -1 ? ( //TODO REFACTOR
								<TableCell
									key={headCellId + index}
									align='left'
									sortDirection={orderBy === headCellId ? order : false}
									classes={{ root: Style['table-cell-root'] }}
								/>
							) : data.coumnsWithoutSorting?.includes(index) ? (
								<TableCell
									key={headCellId + index}
									align={index === 0 ? 'left' : 'center'}
									sortDirection={orderBy === headCellId ? order : false}
									classes={{ root: Style['table-cell-root-alt'] }}
								>
									{headCell.toUpperCase()}
								</TableCell>
							) : (
								<TableCell
									key={headCellId + index}
									align={index > 0 ? 'center' : 'left'}
									sortDirection={orderBy === headCellId ? order : false}
									classes={{ root: Style['table-cell-root'] }}
								>
									<TableSortLabel
										classes={{
											root:
												Style[
													orderBy === headCellId ? 'header-text-active' : 'header-text'
												],
										}}
										IconComponent={() =>
											topResults || (
												<Icon
													className='fas fa-sort'
													fontSize='small'
													classes={{ root: Style['icon-root'] }}
												/>
											)
										}
										active={orderBy === headCell}
										direction={orderBy === headCellId ? order : 'asc'}
										onClick={createSortHandler(headCellId)}
									>
										{headCell.toUpperCase()}
									</TableSortLabel>
								</TableCell>
							);
					  })}
			</TableRow>
		</TableHead>
	);
};
