import React from 'react';
import AvtatarPlaceholder from '../views/website/images/kyle-gawley.jpg';
import MenuBookIcon from '@material-ui/icons/MenuBook';

export const mockSalesData = [
	{ label: 'Leads', number1: '3:1', number2: '$5,000,000.00' },
	{ label: 'Calls', number1: '95%', number2: '$3,800,000.00' },
	{ label: 'Appointments', number1: '70', number2: '$2,800,000.00' },
	{ label: 'Showings', number1: '8:0', number2: '$2,600,000.00' },
	{ label: 'Agreements', number1: '1:0', number2: '$800,000.00' },
	{ label: 'ESCROW', number1: '1:5', number2: '$600,000.00' },
	{ label: 'Closed', number1: '9', number2: '$400,000.00' },
];

export const mockSalesDataSecond = [
	{ label: 'Potential revenue', number1: '$400,000' },
	{ label: 'Average ROI', number1: '$1,400' },
	{ label: 'Return/$1 spend', number1: '$100' },
	{ label: 'Cost per lead', number1: '$14.65' },
	{ label: 'Ave revenue/lead', number1: '$5000' },
	{ label: 'Lead:close', number1: '10:1' },
];

export const tempOSABarFooter = [
	{ label: 'Stale Leeds', number1: '2' },
	{ label: 'Lds : Clls', number1: '4:1' },
	{ label: 'Clls : Apptmnts', number1: '4:1' },
	{ label: 'Apptmnts : Shwngs', number1: '4:1' },
	{ label: 'Shwngs : Agrmnts', number1: '4:1' },
	{ label: 'Agrmnts : ESCROW', number1: '10:1' },
	{ label: 'ESCROW : Clsd', number1: '10:1' },
];

export const tempISABarFooter = [
	{ label: 'Potential revenue', number1: '$400,000' },
	{ label: 'Average ROI', number1: '$1,400' },
	{ label: 'Return/$1 spend', number1: '$100' },
	{ label: 'Cost per lead', number1: '$14.65' },
	{ label: 'Ave revenue/lead', number1: '$5000' },
	{ label: 'Lead:close', number1: '10:1' },
];

export const mockSalesDataThird = [
	{ label: 'Canceled', number1: '3', number2: '$15,000.00' },
	{ label: 'Pending', number1: '13', number2: '$65,000.00' },
	{ label: 'Closed', number1: '4', number2: '$18,000.00' },
];

export const mockCompareData = [
	{ label: 'Total Leads', number1: 300, number2: 0 },
	{ label: 'ROI - %', number1: 20.1, number2: 33.8 },
	{ label: 'ROI - $', number1: '4,200.12', number2: 25 },
	{ label: 'Campaigns:Channel', number1: 1.5, number2: 5 },
	{ label: 'Ave cost per lead', number1: 3.45, number2: -45 },
];

export const CompareFieldsPlaceholderStr = ['', '%', '$', '', '$'];

export const mockSalesData4 = [
	{ label: 'This Month', number1: '3:1', number2: null },
	{ label: 'Last Month', number1: '4:1', number2: null },
	{ label: 'Average', number1: '4:1', number2: null },
];
export const mockSalesData5 = [
	{ label: 'This Month', number1: '$1,000', number2: null },
	{ label: 'Last Month', number1: '$1,200', number2: null },
	{ label: 'Average', number1: '$1,150', number2: null },
];
export const mockSalesData5Str = ['$', '$', '$'];

export const mockCompareDataSecond = [
	{ label: 'Total marketng spend', number1: '24,001.12', number2: 25 },
];
export const CompareStrSecond = ['$'];

export const mockCallsData = [
	{ label: 'Calls made', number1: 75, number2: 0 },
	{ label: 'Connected', number1: 75, number2: 44.9 },
	{ label: 'Conversations', number1: 15, number2: -10 },
	{ label: 'Calls missed', number1: 45, number2: 30.2 },
	{ label: 'Ave time to respond', number1: 20, number2: 25.8 },
];
export const CallsPlaceholdrStr = ['', '', '', '', 'm'];

export const mockAppointmData = [
	{ label: 'Appts made', number1: 110, number2: 20 },
	{ label: 'No show Ave', number1: 38, number2: 44.9 },
	{ label: 'Rescheduled', number1: 21, number2: -10 },
	{ label: 'Agreements Signed', number1: 49, number2: 30.2 },
	{ label: 'Missing Disposition', number1: 34, number2: 25.8 },
];
export const AppointmPlaceholdrStr = ['', '%', '', '', ''];

export const LineChartData = [
	{ label: 'Current', number: '$5M', value: '50%', color: '#C361E5' },
	{ label: 'Projected', number: '$9.6M', value: '96%', color: '#1FF2FF' },
	{ label: 'Goal', number: '$10M', value: '100%', color: '#6179E5' },
];

export const TopAgentsData = [
	{ label: 'Most closed sales', name: 'James Webb', avatar: AvtatarPlaceholder },
	{ label: '#2 Closed sales', name: 'Jessica Hyde', avatar: null },
	{ label: 'Most appointments', name: 'Alex Jones', avatar: AvtatarPlaceholder },
	{ label: 'Most referrals', name: 'Robert Zubrin', avatar: '/none' },
	{ label: 'Top shwngs:agrmnts', name: 'John Doe', avatar: AvtatarPlaceholder },
];

//-------------Lists

export const isaDta = {
	headerName: 'ISAs',
	labels: [
		'agent',
		'leads',
		'calls',
		'appntmts',
		'showings',
		'agrmnts',
		'escrow',
		'closed',
	],
	iconInColumns: [0],
	dataSet: [
		{
			icons: [
				{ index: 0, type: 'avatar', link: AvtatarPlaceholder, color: '#6179E5' },
			],
			values: [
				['Savannah Nguyen'],
				[10, '$50.0K'],
				[10, '$50.0K'],
				[10, '$50.0K'],
				['5:1', '4:1'],
				['1m30s', '1m'],
				['', ''],
				['', ''],
			],
		},
		{
			icons: [
				{ index: 0, type: 'avatar', link: AvtatarPlaceholder, color: '#6179E5' },
			],
			values: [
				['Guy Dutsy'],
				[10, '$50.0K'],
				[10, '$50.0K'],
				[10, '$50.0K'],
				['5:1', '4:1'],
				['1m30s', '1m'],
				['', ''],
				['', ''],
			],
		},
		{
			icons: [{ index: 0, type: 'avatar', link: '', color: '#6179E5' }],
			values: [
				['Sam Rigel'],
				[10, '$50.0K'],
				[10, '$50.0K'],
				[10, '$50.0K'],
				['5:1', '4:1'],
				['1m30s', '1m'],
				['', ''],
				['', ''],
			],
		},
	],
};
export const osaDta = {
	headerName: 'OSAs',
	labels: [
		'AGENT',
		'LEADS',
		'CALLS',
		'APPNTMNTS',
		'SHOWINGS',
		'AGRMNTS',
		'ESCROW',
		'CLOSED',
	],
	iconInColumns: [0],

	dataSet: [
		{
			icons: [
				{ index: 0, type: 'avatar', link: AvtatarPlaceholder, color: '#6179E5' },
			],
			values: [
				['Savannah Nguyen'],
				[11, '$50.0K'],
				[13, '$50.0K'],
				[10, '$50.0K'],
				['5:1', '4:1'],
				['1m30s', '1m'],
				[10, '$50.0K'],
				[10, '$50.0K'],
			],
		},
		{
			icons: [{ index: 0, type: 'avatar', link: '', color: '#6179E5' }],
			values: [
				['Jessica Hyde'],
				[12, '$50.0K'],
				[18, '$50.0K'],
				[10, '$50.0K'],
				['5:1', '5:1'],
				['2m30s', '2m'],
				[10, '$50.0K'],
				[10, '$50.0K'],
			],
		},
		{
			icons: [
				{ index: 0, type: 'avatar', link: AvtatarPlaceholder, color: '#6179E5' },
			],
			values: [
				['Gregor Gotsy'],
				[10, '$50.0K'],
				[20, '$50.0K'],
				[10, '$50.0K'],
				['6:1', '5:1'],
				['4m', '3m57s'],
				[10, '$50.0K'],
				[10, '$50.0K'],
			],
		},
	],
};

export const leadsDta = {
	headerName: 'Leads',
	labels: [
		'MARKETING CHANNEL',
		'AD SPEND',
		'# LEADS',
		'COST LEAD',
		'SALES CLOSED',
		'LEADS:CLOSED',
		'ROI',
	],
	customCells: [{ index: 0, celltype: 'with-icon' }],
	dataSet: [
		{
			icons: [{ index: 0, type: 'icon', link: 'facebook', color: '#475A96' }],
			values: [
				['Facebook'],
				['$10,210.40', '15%'],
				[10, '20%'],
				['$16.21', '$14.22'],
				[11, '10%'],
				['10:1', '10%'],
				['14,000.00', '1.21'],
			],
		},
		{
			icons: [{ index: 0, type: 'icon', link: 'g_translate', color: '#fff' }],
			values: [
				['Google'],
				['$9,310.40', '15%'],
				[22, '25%'],
				['$16.21', '$14.22'],
				[15, '100%'],
				['15:1', '50%'],
				['13,000.00', '1.21'],
			],
		},
		{
			icons: [{ index: 0, type: 'icon', link: 'drafts', color: '#046AFF' }],
			values: [
				['Zillow'],
				['$8,994.40', '16%'],
				[39, '44%'],
				['$16.21', '$14.22'],
				[12, '70%'],
				['20:1', '90%'],
				['12,000.00', '1.21'],
			],
		},
		{
			icons: [{ index: 0, type: 'icon', link: 'drafts', color: '#FF0000' }],
			values: [
				['Youtube'],
				['$8,994.40', '16%'],
				[39, '44%'],
				['$16.21', '$14.22'],
				[12, '70%'],
				['20:1', '90%'],
				['11,000.00', '1.21'],
			],
		},
		{
			icons: [{ index: 0, type: 'icon', link: 'drafts', color: '#55ACEE' }],
			values: [
				['Twitter'],
				['$8,994.40', '16%'],
				[39, '44%'],
				['$16.21', '$14.22'],
				[12, '70%'],
				['20:1', '90%'],
				['15,000.00', '1.21'],
			],
		},
	],
};

export const transactionsDta = {
	headerName: 'Transactions',
	labels: [
		'ADDRESS',
		'AGENT',
		'CLIENT',
		'COMMISION',
		'GROSS PROFIT',
		'DATE CLOSED',
	],
	iconInColumns: [0],
	dataSet: [
		{
			icons: [
				{ index: 0, type: 'icon', link: 'mark_email_read', color: '#6179E5' },
			],
			values: [
				['12345 Address Dr, City, State, 12345'],
				['Sarrah C.'],
				['James B.'],
				['$5,877.99'],
				['$5,877.99'],
				['April 10, 2020'],
			],
		},
		{
			icons: [
				{ index: 0, type: 'icon', link: 'mark_email_read', color: '#6179E5' },
			],
			values: [
				['12345 Address Dr, City, State, 12345'],
				['Sarrah C.'],
				['James B.'],
				['$5,877.99'],
				['$5,877.99'],
				['April 10, 2020'],
			],
		},
		{
			icons: [
				{ index: 0, type: 'icon', link: 'mark_email_read', color: '#C361E5' },
			],
			values: [
				['12345 Address Dr, City, State, 12345'],
				['Sarrah C.'],
				['James B.'],
				['$5,877.99'],
				['$5,877.99'],
				['April 10, 2020'],
			],
		},
		{
			icons: [
				{ index: 0, type: 'icon', link: 'mark_email_read', color: '#6179E5' },
			],
			values: [
				['12345 Address Dr, City, State, 12345'],
				['Sarrah C.'],
				['James B.'],
				['$5,877.99'],
				['$5,877.99'],
				['April 10, 2020'],
			],
		},
		{
			icons: [
				{ index: 0, type: 'icon', link: 'mark_email_read', color: '#C361E5' },
			],
			values: [
				['12345 Address Dr, City, State, 12345'],
				['Sarrah C.'],
				['James B.'],
				['$5,877.99'],
				['$5,877.99'],
				['April 10, 2020'],
			],
		},
		{
			icons: [
				{ index: 0, type: 'icon', link: 'mark_email_read', color: '#C361E5' },
			],
			values: [
				['12345 Address Dr, City, State, 12345'],
				['Sarrah C.'],
				['James B.'],
				['$5,877.99'],
				['$5,877.99'],
				['April 10, 2020'],
			],
		},
		{
			icons: [
				{ index: 0, type: 'icon', link: 'mark_email_read', color: '#C361E5' },
			],
			values: [
				['12345 Address Dr, City, State, 12345'],
				['Sarrah C.'],
				['James B.'],
				['$5,877.99'],
				['$5,877.99'],
				['April 10, 2020'],
			],
		},
	],
};

export const CallsListData = {
	headerName: '',
	labels: [
		'customer',
		'ISA assigned',
		'osa agent',
		'last contacted',
		'lead source',
		'buyer type',
	],
	customCells: [
		{ index: 1, celltype: 'with-icon' },
		{ index: 2, celltype: 'with-icon' },
	],
	dataSet: [
		{
			icons: [
				{ index: 1, type: 'avatar', link: '', color: '#6179E5' },
				{ index: 2, type: 'avatar', link: AvtatarPlaceholder, color: '#6179E5' },
			],

			values: [
				['Mark Gundersson'],
				['Sarrah C.'],
				['James B.'],
				['Aug 20, 2020'],
				['Radio ad'],
				['Seller'],
			],
		},
		{
			icons: [
				{ index: 1, type: 'avatar', link: '', color: '#6179E5' },
				{ index: 2, type: 'avatar', link: AvtatarPlaceholder, color: '#6179E5' },
			],

			values: [
				['Mark Gundersson'],
				['Sarrah C.'],
				['James B.'],
				['Aug 20, 2020'],
				['Radio ad'],
				['Seller'],
			],
		},
		{
			icons: [
				{ index: 1, type: 'avatar', link: '', color: '#6179E5' },
				{ index: 2, type: 'avatar', link: AvtatarPlaceholder, color: '#6179E5' },
			],

			values: [
				['Mark Gundersson'],
				['Sarrah C.'],
				['James B.'],
				['Aug 20, 2020'],
				['Radio ad'],
				['Seller'],
			],
		},
		{
			icons: [
				{ index: 1, type: 'avatar', link: '', color: '#6179E5' },
				{ index: 2, type: 'avatar', link: AvtatarPlaceholder, color: '#6179E5' },
			],

			values: [
				['Mark Gundersson'],
				['Sarrah C.'],
				['James B.'],
				['Aug 20, 2020'],
				['Radio ad'],
				['Seller'],
			],
		},
		{
			icons: [
				{ index: 1, type: 'avatar', link: '', color: '#6179E5' },
				{ index: 2, type: 'avatar', link: AvtatarPlaceholder, color: '#6179E5' },
			],

			values: [
				['Mark Gundersson'],
				['Sarrah C.'],
				['James B.'],
				['Aug 20, 2020'],
				['Radio ad'],
				['Seller'],
			],
		},
		{
			icons: [
				{ index: 1, type: 'avatar', link: '', color: '#6179E5' },
				{ index: 2, type: 'avatar', link: AvtatarPlaceholder, color: '#6179E5' },
			],

			values: [
				['Mark Gundersson'],
				['Sarrah C.'],
				['James B.'],
				['Aug 20, 2020'],
				['Radio ad'],
				['Seller'],
			],
		},
		{
			icons: [
				{ index: 1, type: 'avatar', link: '', color: '#6179E5' },
				{ index: 2, type: 'avatar', link: AvtatarPlaceholder, color: '#6179E5' },
			],

			values: [
				['Mark Gundersson'],
				['Sarrah C.'],
				['James B.'],
				['Aug 20, 2020'],
				['Radio ad'],
				['Seller'],
			],
		},
		{
			icons: [
				{ index: 1, type: 'avatar', link: '', color: '#6179E5' },
				{ index: 2, type: 'avatar', link: AvtatarPlaceholder, color: '#6179E5' },
			],

			values: [
				['Mark Gundersson'],
				['Sarrah C.'],
				['James B.'],
				['Aug 20, 2020'],
				['Radio ad'],
				['Seller'],
			],
		},
	],
};
export const AppointmentsListData = {
	headerName: '',
	labels: [
		'agent',
		'leads',
		'calls',
		'appntmnts',
		'showings',
		'agrmnts',
		'escrow',
		'closed',
	],
	iconInColumns: [0],
	dataSet: [
		{
			icons: [{ index: 0, type: 'avatar', link: '', color: '#6179E5' }],

			values: [['Mark Gunderssons'], [10], [10], [10], [10], [10], [10], [10]],
		},
		{
			icons: [{ index: 0, type: 'avatar', link: '', color: '#6179E5' }],

			values: [['Chelsea Chow'], [10], [10], [10], [10], [10], [10], [10]],
		},
		{
			icons: [{ index: 0, type: 'avatar', link: '', color: '#6179E5' }],

			values: [['Alex Doe'], [10], [10], [10], [10], [10], [10], [10]],
		},
	],
};
export const ListDataTemplate = {
	headerName: '',
	labels: [], //array of string
	iconInColumns: [], //array of integers
	customCells: [{ index: 2, celltype: 'chips' }], //special cells
	dataSet: [
		{
			icons: [
				//{ index: int, type: str, link: str, color: srt },
			],
			values: [
				[],
				//['btn title', 'color',"conformation popup text"],
			],
			id: null,
		},
		{
			icons: [],
			values: [],
		},
	],
};

export const ossaDta = {
	headerName: 'OSAs',
	labels: [
		'AGENT',
		'LEADS',
		'CALLS',
		'APPNTMNTS',
		'SHOWINGS',
		'AGRMNTS',
		'ESCROW',
		'CLOSED',
	],
	dataSet: [
		{
			label: 'Savannah Nguyen',
			icon: { type: 'avatar', link: '' },
			values: [
				[11, '$50.0K'],
				[13, '$50.0K'],
				[10, '$50.0K'],
				['5:1', '4:1'],
				['1m30s', '1m'],
				[10, '$50.0K'],
				[10, '$50.0K'],
			],
			badge: [0],
		},
		{
			label: 'Jessica Hyde',
			icon: { type: 'avatar', link: '' },
			values: [
				[12, '$50.0K'],
				[18, '$50.0K'],
				[10, '$50.0K'],
				['5:1', '5:1'],
				['2m30s', '2m'],
				[10, '$50.0K'],
				[10, '$50.0K'],
			],

			badge: [3],
		},
		{
			label: 'Gregor Gotsy',
			icon: { type: 'avatar', link: '' },
			values: [
				[10, '$50.0K'],
				[20, '$50.0K'],
				[10, '$50.0K'],
				['6:1', '5:1'],
				['4m', '3m57s'],
				[10, '$50.0K'],
				[10, '$50.0K'],
			],
			badge: [],
		},
	],
};

export const IsaTemp = {
	headerName: '',
	labels: [
		'Customer',
		'Type',
		'Stage',
		'Action Required',
		'Lead Received',
		'Last Contact',
		'Action Form',
	], //array of string

	iconInColumns: [0],

	coumnsWithoutSorting: [6],

	customCells: [
		{ index: 0, celltype: 'clickable' },
		{ index: 6, celltype: 'btn-icon' },
	], //special cells

	dataSet: [
		{
			icons: [{ index: 0, type: 'avatar', link: '', color: '#6179E5' }],
			values: [
				['Floyd Miles'],
				['Buyer'],
				['Call'],
				['Feb 14, 2020'],
				['July 24, 2020'],
				[['form', 'primary', <MenuBookIcon />]],
			],
			id: null,
		},
	],
};
