/***
 *
 *   NOTIFICATION
 *   Banner notification that appears at the top of the screen.
 *   Create a notification anytime using context.notification.show()
 *   and pass the props below
 *
 *   PROPS
 *   message: string containing the message
 *   type - success/error/warning
 *   autoclose - if false, the notification won't disapear until clicking the X
 *
 **********/

import React, { useContext } from 'react';
import { ViewContext, Button } from 'components/lib'; //reused
import { CSSTransition } from 'react-transition-group';
import './notification.scss';

export function Notification(props) {
	const context = useContext(ViewContext);

	return (
		<CSSTransition in appear timeout={0} classNames='notification'>
			<div className={`notification ${props.type}`}>
				{context.notification.data.text}
				{!context.notification.data.autoclose && (
					<Button
						className='btn-close-notification'
						icon='x'
						color='light'
						action={context.notification.hide}
					/>
				)}
			</div>
		</CSSTransition>
	);
}
