/*** 
 * 
 * Card Component
 * 
 * Usage
<>
 <CardAlt> 
  <CardAlt.Box type="header"> header </CardAlt.Box>
  <CardAlt.Box type="body"> body </CardAlt.Box>
  <CardAlt.Box type="footer"> footer </CardAlt.Box>
 </CardAlt>
<> 
***/

import React, { Component } from 'react';
import { Grid, Card } from '@material-ui/core';

export class CardAlt extends Component {
	static Box = ({ children, type, heights, justify, alignItems, direction }) => (
		<Grid
			item
			container
			className={`CardLayout-${type}`}
			style={{ height: heights }}
			direction={direction}
			justify={justify}
			alignItems={alignItems}
		>
			{children}
		</Grid>
	);

	render() {
		return (
			<Grid
				container
				direction='column'
				justify='space-between'
				component={Card}
				style={{ minHeight: this.props.height }}
				className='CardLayout'
			>
				{this.props.children}
			</Grid>
		);
	}
}
