import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';

//import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import MenuBookIcon from '@material-ui/icons/MenuBook';
// import VoiceChatOutlinedIcon from '@material-ui/icons/VoiceChatOutlined';
// import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';

export const appMenuItems = [
	{
		name: 'Account settings',
		Icon: SettingsOutlinedIcon,

		items: [
			{
				name: 'Profile',
				link: '/profile',
			},
			{
				name: 'Roles',
				link: '/account/roles',
			},
			{
				name: 'Users',
				link: '/users_managment/members',
			},
			{
				name: 'Account',
				link: '/account/settings',
			},
		],
	},
];
