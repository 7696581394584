import { RolesList } from 'views/accountSettings/roles/rolesList';
import { Settings } from 'views/accountSettings/account/settings';
// import { Subscription } from 'views/accountSettings/account/subscription';
// import { PaymentMethod } from 'views/accountSettings/account/paymentMethod';
import { Invites } from 'views/accountSettings/usersManagment/invites';
import { Members } from 'views/accountSettings/usersManagment/members';
import { MemberPage } from 'views/accountSettings/usersManagment/memberPage';

import { Profile } from 'views/userProfile/profile';
import { RolesEdit } from 'views/accountSettings//roles/rolesEdit';

export default [
	//mui new
	{
		path: '/account/roles',
		view: RolesList,
		layout: 'appMUI',
		//permission: 'user',
		title: 'Your Account Roles',
	},

	{
		path: '/account/roles/edit',
		view: RolesEdit,
		layout: 'appMUI',
		//permission: 'user',
		title: 'Edit role',
	},
	{
		path: '/account/settings',
		view: Settings,
		layout: 'appMUI',
		//permission: 'user',
		title: 'Your Account Settings',
	},
	// {
	// 	path: '/account/subscription',
	// 	view: Subscription,
	// 	layout: 'appMUI',
	// 	//permission: 'user',
	// 	title: 'Your Account Subscription',
	// },
	// {
	// 	path: '/account/payment_method',
	// 	view: PaymentMethod,
	// 	layout: 'appMUI',
	// 	//permission: 'user',
	// 	title: 'Your Account Payment Method',
	// },
	{
		path: '/users_managment/invites',
		view: Invites,
		layout: 'appMUI',
		//permission: 'user',
		title: 'Invites Managment',
	},
	{
		path: '/users_managment/members',
		view: Members,
		layout: 'appMUI',
		//permission: 'user',
		title: 'Members Managment',
	},
	{
		path: '/users_managment/members/personal',
		view: MemberPage,
		layout: 'appMUI',
		//permission: 'user',
		title: 'Member personal page',
	},

	{
		path: '/profile',
		view: Profile,
		layout: 'appMUI',
		//permission: 'user',
		title: 'Account Settings',
	},
];
