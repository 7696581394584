/***
 *
 *   SIGN IN
 *   Sign in form for all account types (including master).
 *
 **********/

import React, { useContext } from 'react';

import {
	Form,
	ContentSection,
	CardMUI as Card,
	useNavItems,
	Animate,
	AuthContext,
} from 'components/lib';
import { Grid } from '@material-ui/core';

export function Signin(props) {
	const context = useContext(AuthContext);
	let { getRoutes } = useNavItems();

	const afterAuthFn = (res) => {
		context.signinWip(res);
		let tree = getRoutes();
		window.location = tree.length > 0 ? tree[0].link : '/profile';
	};

	return (
		<Animate type='pop'>
			<Grid
				container
				direction='column'
				justify='flex-start'
				style={{
					padding: '4em 0',
					alignItems: 'center',
					minHeight: '100vh',
				}}
			>
				<ContentSection type='home' title='Sign In'>
					<Card restrictWidth center>
						<Form
							data={{
								email: {
									label: 'Email',
									type: 'email',
									required: true,
								},
								password: {
									label: 'Password',
									type: 'password',
									required: true,
								},
								forgotpassword: {
									type: 'link',
									url: '/forgotpassword',
									text: 'Forgot your password?',
								},
							}}
							url='/auth/signin'
							method='POST'
							buttonText='Sign In'
							callback={(res) => afterAuthFn(res)}
						/>
					</Card>
				</ContentSection>
			</Grid>
		</Animate>
	);
}
