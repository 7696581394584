/***
 *
 *   PROFILE
 *   Update the user profile
 *
 **********/

import React, { useContext, useEffect, useState, useRef } from 'react';

import {
	Grid,
	Button,
	Paper,
	Typography,
	Divider,
	Popover,
} from '@material-ui/core';
import { Form, AuthContext } from 'components/lib';
// import { makeStyles } from '@material-ui/core/styles';
import Style from './change_pwd.module.scss';

export function LoginInfo(props) {
	let { randPwd, userData, isMembmer = false, isCompany = false } = props;

	const popperAnchor = useRef(null);
	//TODO: callback and user profile update after API change
	const [user, setUser] = useState(false);
	const [randPswd, setRandPswd] = useState('');

	useEffect(() => {
		randPwd && setRandPswd(randPwd);
	}, [randPwd]);

	useEffect(() => {
		setUser(userData);
	}, [userData]);

	//popover
	const [anchorEl, setAnchorEl] = React.useState(null);
	const context = useContext(AuthContext);

	//POPOVER
	const handleClick = () => {
		setAnchorEl(popperAnchor.current);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	return (
		<>
			<Grid
				item
				xs={3}
				style={{ minHeight: 200, minWidth: 200 }}
				ref={popperAnchor}
			>
				<Paper
					style={{
						minHeight: 210,
						maxHeight: 210,
						minWidth: 200,
						width: '100%',
						borderRadius: 10,
					}}
				>
					<Grid
						container
						direction='column'
						justify='space-between'
						//alignItems='center'
						style={{ padding: 10, minHeight: 200 }}
					>
						<Grid item>
							<Typography
								color='textPrimary'
								variant='h5'
								style={{ marginBottom: '1em' }}
							>
								Login Info
							</Typography>
							<Grid item container direction='row' justify='space-between'>
								<Typography color='textSecondary' variant='h6'>
									Email
								</Typography>
								<Typography color='textSecondary' variant='h6'>
									{user.email}
								</Typography>
							</Grid>
							<Divider style={{ backgroundColor: '#0d1014', margin: '5px 0' }} />
							<Grid
								item
								container
								direction='row'
								justify='space-between'
								style={{ marginBottom: 10 }}
							>
								<Typography color='textSecondary' variant='h6'>
									Password
								</Typography>
								<Typography color='textPrimary' variant='h6'>
									{randPswd}
								</Typography>
							</Grid>
						</Grid>
						<Grid item container direction='row' justify='flex-end'>
							<Button
								variant='contained'
								color='primary'
								onClick={handleClick}
								disabled={isMembmer}
							>
								update password
							</Button>
						</Grid>
						<Popover
							id={id}
							open={open}
							anchorEl={anchorEl}
							//anchorReference={popperAnchor}
							onClose={handleClose}
							anchorOrigin={{
								vertical: 'center',
								horizontal: 'center',
							}}
							transformOrigin={{
								vertical: 'center',
								horizontal: 'center',
							}}
							classes={{ root: Style['pwd-change-alt'] }}
						>
							<Paper
								style={{
									minHeight: 200,
									minWidth: 250,
									width: '100%',
									borderRadius: 10,
									padding: 10,
								}}
							>
								<Form
									data={{
										oldPassword: {
											label: 'Old Password',
											type: 'password',
											required: true,
										},
										password: {
											label: 'New Password',
											type: 'password',
											required: true,
											compareTo: true,
										},
										repeatedPassword: {
											label: 'Repeat New Password',
											type: 'password',
											required: true,
											compare: true,
											errorMessage: 'passwords does not match',
										},
									}}
									url='/api/user/password'
									method='PUT'
									buttonText='Change Password'
									callback={context.pwdChangeWip}
									//callback={(res) => callbackFn(res)}
								/>
							</Paper>
						</Popover>
					</Grid>
				</Paper>
			</Grid>
		</>
	);
}

// const useStyles = makeStyles(() => ({
// 	input: {
// 		'& > *': {
// 			textAlign: 'end',
// 			padding: '3px 0 0px',
// 		},
// 	},
// }));
