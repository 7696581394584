// import { MasterDashboard } from 'views/master/dashboard';
// import { MasterAccounts } from 'views/master/accounts';
// import { MasterUsers } from 'views/master/users';

export default [
	// {
	//   path: '/master/dashboard',
	//   view: MasterDashboard,
	//   layout: 'master',
	//   permission: 'master',
	//   title: 'Master Dashboard'
	// },
	// {
	//   path: '/master/accounts',
	//   view: MasterAccounts,
	//   layout: 'master',
	//   permission: 'master',
	//   title: 'Accounts'
	// },
	// {
	//   path: '/master/users',
	//   view: MasterUsers,
	//   layout: 'master',
	//   permission: 'master',
	//   title: 'Users'
	// },
];
