/***
 *
 *   BUTTON
 *   Can be a standard button, icon button or with loading animation
 *
 *   PROPS
 *   text: button label
 *   action: callback function executed on click
 *   params: object passed to the callback function (optional)
 *   color: red/blue (default: green)
 *   icon: icon image (optional)
 *   small: render a smaller button
 *   textOnly: text only
 *   outline: outline button
 *   className: pass a custom class object
 *   fullWidth: extend to full width of parent container
 *   loading: boolean to toggle loading animation (optional)
 *
 **********/

import React, { Fragment } from 'react';
import ClassNames from 'classnames';
import { Icon } from 'components/lib';
import Style from './button.module.scss';

export function Button(props) {
	const isLoader = props.hasOwnProperty('loading');

	const btnCss = ClassNames([
		props.className,
		props.color && Style[props.color],
		props.icon && Style.icon,
		props.small && Style.small,
		props.big && Style.big,
		props.textOnly && Style.text,
		props.outline && Style.outline,
		props.fullWidth && Style.fullWidth,
		!props.textOnly && !props.icon && Style.btn,
	]);

	const loaderCss = ClassNames([
		'btnLoader',
		Style.loader,
		props.loading && Style.loading,
		props.fullWidth && Style.fullWidth,
	]);

	const button = (
		<button
			title={props.title}
			className={btnCss}
			onClick={(e) => {
				e.preventDefault();
				e.stopPropagation();
				props.action && props.action(props.params);
			}}
		>
			{props.icon ? (
				<Icon image={props.icon} color={props.color} size={props.size} />
			) : (
				props.text
			)}
		</button>
	);

	return (
		<Fragment>
			{isLoader ? <div className={loaderCss}>{button}</div> : button}
		</Fragment>
	);
}
