import { createMuiTheme } from '@material-ui/core/styles';

import Style from '../global.scss';
import AvenirRoman from 'fonts/Avenir Roman.otf';
import AvenirHeavy from '../../fonts/AEH.ttf';

const AvenirR = {
	fontFamily: 'AvenirRoman',
	fontStyle: 'normal',
	fontWeight: 400,
	src: [`url('.${AvenirRoman}') format('woff')`].join(','),
};
const AvenirH = {
	fontFamily: 'AvenirHeavy',
	fontStyle: 'normal',
	fontWeight: 400,
	src: [`url('.${AvenirHeavy}') format('woff')`].join(','),
};

export const darkTheme = createMuiTheme({
	palette: {
		type: 'dark',
		background: {
			paper: Style['c-black-Module'],
			sidebar: Style['c-black-Sidebar'],
			default: '#262C35',
		},
		text: {
			primary: Style['c-text-White'],
			secondary: Style['c-text-Grey'],
		},
		primary: {
			main: Style['c-Purple'],
		},
		secondary:{
			main:"#e56161"
		}
	},
	typography: {
		//fontFamily: 'AvenirHeavy, AvenirRoman, cursive',
		fontFamily: 'AvenirHeavy, AvenirRoman, serif',
		fontSize: 16,
		h4: {
			fontSize: '1.3125em',
			fontWeight: 800,
			lineHeight: '1.8125em',
		},
		h2: {
			fontSize: '2.625em',
			fontWeight: 800,
		},
		h5: {
			fontSize: '0.875em',
			fontWeight: 800,
		},
		h6: {
			fontSize: '0.75em',
			fontWeight: 800,
		},
		subtitle1: {
			fontSize: '1em',
			fontWeight: 800,
		},
	},

	overrides: {
		MuiCssBaseline: {
			'@global': {
				'@font-face': [AvenirH, AvenirR],
			},
		},
	},
});
