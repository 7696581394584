import React, { useContext, useEffect, useState } from 'react';
import { useAPI, apiAxiosCall } from 'components/lib';

const roleTemplate = {
	name: 'New Role',
	permissions: [],
};

const initState = {
	selectedRole: false,
	allPermissions: false,
	allRoles: false,
};

const RolesContext = React.createContext({
	...initState,
	setCurrentRoleFn: () => {},
	updateRoleFn: () => {},
	createRoleFn: () => {},
	addRoleFn: () => {},
	deleteRoleFn: () => {},
	updateRolesDataFn: () => {},
	duplicateRoleFn: () => {},
	clearSelectedRoleFn: () => {},
	saveRoleFn: () => {},
});

export const useRoleItems = () => {
	return useContext(RolesContext);
};

export const RoleProvider = (props) => {
	const [roleState, setRoleState] = useState(initState);
	const permissionsRequest = useAPI('/api/functionality');
	const requestRoles = useAPI('/api/role');

	const setCurrentRoleFn = (selectedRole) => {
		if (!selectedRole) return false;
		//storing selected role
		setRoleState((previousState) => {
			return {
				...previousState,
				selectedRole,
			};
		});
	};

	const setPermissionsFn = (allPermissions) => {
		setRoleState((previousState) => {
			return {
				...previousState,
				allPermissions,
			};
		});
	};

	const setRolesFn = (allRoles) => {
		setRoleState((previousState) => {
			return {
				...previousState,
				allRoles,
			};
		});
	};
	const updateRoleFn = async (data) => {
		//console.log('role data to send', data);
		if (!data) return false;
		try {
			const res = await apiAxiosCall('/api/role', 'PUT', data);
			if (res.err) return false;
		} catch (e) {
			console.log('error in roles context PUT');
			return false;
		}
		return true;
	};

	const createRoleFn = async (data, customName = false, isDuplicate = false) => {
		if (isDuplicate && !data) return false;
		const newRoleTemplate = { ...roleTemplate };
		const templateName = customName ? customName : newRoleTemplate.name;
		const allRoles = roleState.allRoles;
		let suffix = 0;
		allRoles.some((el) => {
			let elName = el.name;
			const bool = new RegExp(templateName).test(elName);

			if (bool) {
				let changeName = elName.split('_'); //rewrite to awoid this kind of bugs during duplication Assistant_2_3_4
				let num = parseInt(changeName[changeName.length - 1]);
				if (changeName.length > 1 && !isNaN(num)) {
					if (suffix < num) {
						suffix = num;
					}
				}
			}
			return bool;
		});

		if (isDuplicate) {
			//todo
			newRoleTemplate.name = `${templateName} ${'duplicate'}`;
			newRoleTemplate.permissions = data;
		} else {
			newRoleTemplate.name = `${templateName}_${suffix + 1}`;
		}

		suffix = 0;
		return newRoleTemplate;
	};
	const uploadRoleFn = async (role) => {
		if (!role) return false;

		try {
			const res = await apiAxiosCall('/api/role', 'POST', role);
			if (res.err) return false;

			//reload roles
			updateRolesDataFn();
		} catch (e) {
			console.log('error in roles context POST');
			return false;
		}
		return true;
	};

	const deleteRoleFn = async (roleId) => {
		if (!roleId) return false;
		try {
			const res = await apiAxiosCall(`/api/role/${roleId}`, 'DELETE');
			if (res.err) return false;
			//reload
			updateRolesDataFn();
		} catch (e) {
			console.log('error in roles context DELETE');
			return false;
		}
		return true;
	};

	const updateRolesDataFn = async () => {
		try {
			const res = await apiAxiosCall(`/api/role`);
			if (res.err) return false;

			if (res.data?.length > 0) {
				setRolesFn(res.data);
			}
		} catch (e) {
			console.log('error in roles context GET', e);
			return false;
		}
	};

	useEffect(() => {
		if (permissionsRequest.data?.length > 0) {
			//console.log('funcs', permissionsRequest);
			setPermissionsFn(permissionsRequest.data);
		}
	}, [permissionsRequest]);
	useEffect(() => {
		if (requestRoles.data?.length > 0) {
			console.log('requestRoles', requestRoles);
			setRolesFn(requestRoles.data);
		}
	}, [requestRoles]);

	//TODO fn and display for deleted roles ?deleted=true  not needed for now

	const duplicateRoleFn = async (roleId) => {
		if (!roleId) return false;

		//find role we aim to duplicate with provided id
		const allRoles = roleState.allRoles;
		let role = allRoles.filter((el) => {
			if (el.id === roleId) return el;
			return false;
		})[0];

		if (!role) return false;

		//send api request to add this role as new one
		const newRole = await createRoleFn(role.permissions, role.name, true);
		if (!newRole) return false;
		setCurrentRoleFn(newRole);
		return true;
	};

	const addRoleFn = async () => {
		const role = await createRoleFn();
		setCurrentRoleFn(role);
		return true;
	};

	const clearSelectedRoleFn = () => {
		//clearing role'
		setCurrentRoleFn(false);
	};
	const saveRoleFn = (role) => {
		if (!role) return false;
		if (role.id) {
			return updateRoleFn(role);
		} else {
			return uploadRoleFn(role);
		}
	};
	return (
		<RolesContext.Provider
			value={{
				curentRole: roleState.selectedRole,
				allPermissions: roleState.allPermissions,
				allRoles: roleState.allRoles,
				setCurrentRoleFn,
				updateRoleFn,
				createRoleFn,
				deleteRoleFn,
				updateRolesDataFn,
				duplicateRoleFn,
				addRoleFn,
				clearSelectedRoleFn,
				saveRoleFn,
			}}
		>
			{props.children}
		</RolesContext.Provider>
	);
};
