import React from 'react';
import { Paper, Grid, Divider } from '@material-ui/core';

export function Wrapper(props) {
	const { customStyle = {} } = props;
	return (
		<Paper
			//variant='outlined'
			style={{ ...customStyle }}
		>
			{props.children}
		</Paper>
	);
}

export function PageWrapper(props) {
	const { noDivider = false, customStyle = {} } = props;
	return (
		<Grid
			container
			direction='column'
			justify='flex-start'
			alignItems='flex-start'
			style={{
				paddingTop: '5em',
				minHeight: '100vh',
				maxWidth: '72em',
				//width: '-webkit-fill-available', inherit
				//width: customWidth ? customWidth : '',
				//width: '100%',
				...customStyle,
			}}
		>
			{props.children}
			{noDivider || <Divider style={{ marginBottom: '3em', height: 0 }} />}
		</Grid>
	);
}
