/***
 *

 *
 **********/

import React, { useEffect, useState } from 'react';

import {
	Grid,
	Button,
	TextField,
	FormControl,
	Chip,
	InputLabel,
	Input,
	Select,
	MenuItem,
	CircularProgress,
	Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import {
	ContentSection,
	Loader,
	PageWrapper,
	History,
	useRoleItems,
} from 'components/lib';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
	anchorOrigin: {
		vertical: 'bottom',
		horizontal: 'left',
	},
	transformOrigin: {
		vertical: 'top',
		horizontal: 'left',
	},
	getContentAnchorEl: null,
};

export function RolesEdit(props) {
	const classes = useStyles();
	const [state, setState] = useState({
		permissionsSummaryChips: [],
		roleLabel: '',
		selectedRole: false,
		loader: false,
		msg: false,
	});

	const {
		curentRole,
		allPermissions,
		clearSelectedRoleFn,
		saveRoleFn,
	} = useRoleItems();

	const handleChange = (event) => {
		setState((previousState) => {
			return {
				...previousState,
				permissionsSummaryChips: event.target.value,
			};
		});
	};

	const handleCancelBtnClick = () => {
		clearSelectedRoleFn();
		History.push('/account/roles');
	};

	const compileActualPermissionsArr = () => {
		//eslint-disable-next-line array-callback-return
		const filteredRoles = allPermissions.filter((premission) => {
			const bool = state.permissionsSummaryChips.includes(premission.summary);
			if (bool) return premission;
		});
		return filteredRoles;
	};
	const handleSaveBtnClick = () => {
		/*
			TODO
			redirect on success to the roles list with timeout
			validation
				
				check for the empty name? may be usefull as feature, dunno
				
				check if an id is present
				check for data type (object) and its length down the line in the context
			*/

		const permissions = compileActualPermissionsArr();

		const updateData = {
			id: curentRole.id,
			name: state.roleLabel,
			permissions: permissions,
		};
		setState((previousState) => {
			return {
				...previousState,
				loader: true,
			};
		});
		saveRoleFn(updateData)
			.then((res) => {
				setState((previousState) => {
					return {
						...previousState,
						loader: false,
						msg: res ? 'role is saved' : 'this role is not saved due some error',
					};
				});
				return res;
			})
			.then((res) => {
				if (res) {
					setTimeout(
						() =>
							setState((previousState) => {
								return {
									...previousState,

									msg: 'redirecting to the list',
								};
							}),
						2000
					);

					setTimeout(() => props.handleViewChange('list'), 5000); //remove
				}
			});
	};
	const handleDelete = (chip) => {
		if (state.permissionsSummaryChips?.length > 0) {
			const newChips = [...state.permissionsSummaryChips];
			const index = newChips.indexOf(chip);

			if (index >= 0) {
				newChips.splice(index, 1);
			}
			setState((previousState) => {
				return {
					...previousState,
					permissionsSummaryChips: newChips,
				};
			});
		}
	};

	useEffect(() => {
		let permissionsSummaryChips = [];
		if (curentRole === false) History.push('/account/roles');
		if (curentRole) {
			if (curentRole && curentRole?.permissions?.length > 0) {
				const rolePermissions = [...curentRole.permissions];
				permissionsSummaryChips = rolePermissions.map((chip) => chip.summary);
			}

			setState((previousState) => {
				return {
					...previousState,
					selectedRole: curentRole,
					roleLabel: curentRole.name,
					permissionsSummaryChips,
				};
			});
		}
	}, [curentRole]);

	const handleLabelChange = (event) => {
		const changedLabel = event.target.value;
		setState((previousState) => {
			return {
				...previousState,
				roleLabel: changedLabel,
			};
		});
	};

	const sortPermissions = (permissions) => {
		if (!permissions) return false;
		try {
			//sort names
			permissions.sort((a, b) => {
				//check what type of array we got
				const bool = typeof permissions[0] === 'object' ? true : false;
				//grab first character of name
				const A = bool
					? a.summary.charAt(0).toLowerCase()
					: a.charAt(0).toLowerCase();
				const B = bool
					? b.summary.charAt(0).toLowerCase()
					: b.charAt(0).toLowerCase();
				//compare
				if (A < B) {
					return -1;
				}
				if (A > B) {
					return 1;
				}
				// names must be equal
				return 0;
			});

			return permissions;
		} catch (error) {
			return permissions;
		}
	};

	return (
		<PageWrapper>
			<ContentSection disableButton title='Edit Role'>
				<ContentSection type='large'>
					{!state.selectedRole ? (
						<CircularProgress />
					) : (
						<div style={{ padding: '1em', width: '100%' }}>
							<Grid container direction='column' justify='flex-start'>
								<Grid item>
									<TextField
										label={
											<Typography
												gutterBottom
												align='center'
												classes={{ root: classes.labelTypography }}
											>
												Role Title
											</Typography>
										}
										value={state.roleLabel}
										onChange={handleLabelChange}
										classes={{ root: classes.TextFieldresize }}
										InputLabelProps={{
											shrink: true,
										}}
										// style={{
										// 	padding: '10px 3px',
										// }}
									/>
								</Grid>
								<Grid
									item
									container
									direction='row'
									alignItems='stretch'
									style={{ marginTop: 15 }}
								>
									<FormControl style={{ minWidth: 200, width: '100%' }}>
										<InputLabel
											id='edit-role-permissions-label'
											style={{ fontSize: 20 }}
											shrink
										>
											Permissions
										</InputLabel>
										<Select
											classes={{ selectMenu: classes.selectMenu }}
											multiple
											value={state.permissionsSummaryChips}
											onChange={handleChange}
											input={<Input id='edit-role-permissions-chip' />}
											renderValue={(selected) => (
												<div>
													{sortPermissions(selected).map((value) => (
														<Chip
															key={value + Math.random()}
															label={value}
															color='primary'
															style={{
																fontSize: '12px',
																height: '2em',
																fontFamily: 'AvenirRoman',
																lineHeight: 'normal',
																marginRight: 5,
															}}
															onDelete={() => handleDelete(value)}
															onMouseDown={(event) => {
																event.preventDefault();
																event.stopPropagation();
															}}
														/>
													))}
												</div>
											)}
											MenuProps={MenuProps}
										>
											{sortPermissions(allPermissions).map((chip) => (
												<MenuItem
													key={chip.id ? chip.id : Math.random()}
													value={chip.summary}
												>
													{chip.summary}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>
							</Grid>
							<Grid
								item
								container
								justify='flex-start'
								spacing={1}
								style={{ marginTop: 5 }}
							>
								<Grid item>
									<Button
										variant='contained'
										color='primary'
										//disabled
										onClick={handleSaveBtnClick}
									>
										save
									</Button>
								</Grid>
								<Grid item>
									<Button
										variant='contained'
										color='secondary'
										onClick={handleCancelBtnClick}
									>
										cancel
									</Button>
								</Grid>
							</Grid>
						</div>
					)}
					{state.loader && <Loader />}
					{state.msg && <Typography>{state.msg}</Typography>}
				</ContentSection>
			</ContentSection>
		</PageWrapper>
	);
}

const useStyles = makeStyles((theme) => ({
	selectMenu: {
		whiteSpace: 'pre-wrap',
		textOverflow: 'ellipsis',
		lineHeight: '30px',
		minHeight: '1.1876em',
	},
	TextFieldresize: {
		'& .MuiInput-input': {
			fontSize: 16,
			marginTop: 6,
			paddingLeft: 2,
		},
	},
	labelTypography: { fontSize: 20, marginBottom: 13 },
}));
