/***
 *
 *   FORGOT PASSWORD
 *   Trigger a password reset process
 *
 **********/

import React from 'react';

import { Form, ContentSection, CardMUI as Card, Animate } from 'components/lib';
import { Grid } from '@material-ui/core';

export function ForgotPassword(props) {
	return (
		<Animate type='pop'>
			<Grid
				container
				direction='column'
				justify='flex-start'
				style={{
					padding: '4em 0',
					alignItems: 'center',
					minHeight: '100vh',
				}}
			>
				<ContentSection type='home' title='Forgot Password?'>
					<Card restrictWidth center>
						<p>
							Enter your email address and we'll send you instructions to reset your
							password.
						</p>

						<Form
							data={{
								email: {
									label: 'Email',
									type: 'email',
									required: true,
								},
							}}
							url='/auth/askNewPassword/'
							method='POST'
							buttonText='Reset Password'
							//idFromForm
						/>
					</Card>
				</ContentSection>
			</Grid>
		</Animate>
	);
}
