/***
 *
 *   HOMEPAGE
 *
 **********/

import React, { useEffect, useState } from 'react';

import { Grid, Button } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { useNavItems } from 'components/lib';
import Logo from 'views/website/images/logo.png';

export function Home(props) {
	let token = JSON.parse(localStorage.getItem('accessToken'));
	let { menuItems } = useNavItems();
	const [linkTo, setLinkTo] = useState('/');

	useEffect(() => {
		if (menuItems) {
			const dashboardMenu = menuItems.filter(
				(obj) => obj.name === 'Dashboard'
			)?.[0];
			const newLink =
				dashboardMenu?.items?.length > 0 ? dashboardMenu.items[0].link : '/profile';
			setLinkTo(newLink);
		}
	}, [menuItems]);

	return (
		<Grid
			container
			direction='column'
			justify='flex-start'
			style={{
				paddingTop: '5em',
				minHeight: '100vh',
				alignItems: 'center',
				backgroundColor: '#171c22',
			}}
		>
			<img
				style={{
					width: '50%',
					height: 'auto',
					//padding: 20,
					paddingTop: '10%',
				}}
				src={Logo}
				alt='logo'
			/>

			{token && (
				<Button
					variant='contained'
					color='primary'
					size='large'
					component={RouterLink}
					to={linkTo}
				>
					Go to the App
				</Button>
			)}
		</Grid>
	);
}
