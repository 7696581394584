/***
 *
 *   Members
 *   Manage your members
 *
 **********/

import React, { useEffect, useState } from 'react';

import { Button } from '@material-ui/core';

import {
	ContentSection,
	TableList,
	useMemberInfo,
	dataValidator,
	Loader,
	History,
} from 'components/lib';

const Tabs = [
	{ label: 'Members', link: '/users_managment/members' },
	{ label: 'Invites', link: '/users_managment/invites' },
];

export function MembersTemp(props) {
	const [state, setState] = useState({
		loading: true,
		members: null,
	});
	const { changeView } = props;

	const {
		editMember,
		deleteMember,
		listOfMembers,
		reqListOfMembers,
	} = useMemberInfo();

	const compileList = (apiData) => {
		setState({ ...state, members: false });

		let members = {
			headerName: '',
			labels: ['ID ', 'name', 'Email Adress', 'Roles', 'btn'],
			iconInColumns: [],
			customCells: [{ index: 4, celltype: 'button-nolabel' }],
		};
		let dataSet = [];

		if (apiData) {
			apiData.rows.map((member, memberIndex) => {
				dataSet = [
					...dataSet,
					{
						icons: [],
						id: member.id,
						values: [
							[member.id],

							[
								dataValidator(
									{ firstName: member.firstName, lastName: member.lastName },
									'name-short'
								),
							],
							[member.email],
							[
								member.roles.map((role, index) => {
									return index === member.roles.length - 1
										? `${role.name}`
										: `${role.name}, `;
								}),
							],

							[
								['edit', 'primary'],
								['delete', 'secondary', 'Are you sure you want to exclude this user?'],
							],
							//['btn title', 'color',"conformation popup text"],
						],
					},
				];
			});
			members = { ...members, dataSet };
			setState({ ...state, members });
		}
	};

	useEffect(() => {
		compileList(listOfMembers);
	}, [listOfMembers]);
	const handleBtnClick = (buttonType, row) => {
		if (buttonType[0] === 'edit') {
			if (row.id) {
				editMember(row.id);
			}
		} else if (buttonType[0] === 'delete') {
			//delete selected user
			if (row.id) {
				deleteMember(row.id);
			}
		}
	};

	useEffect(() => {
		reqListOfMembers();
	}, []);

	return (
		<>
			{/* <div> */}
			<ContentSection disableButton title='Members'>
				{state.members ? (
					<>
						<div
							style={{
								width: '100%',
								display: 'flex',
								justifyContent: 'space-between',
								padding: '0 5px',
							}}
						>
							<Button
								variant='contained'
								color='primary'
								onClick={() => History.push('/users_managment/invites')}
							>
								invite user
							</Button>
							{/* <div>search</div> */}
						</div>

						<TableList
							data={state.members}
							callBack={handleBtnClick}
							//optionalComponents={}
							//
						/>
					</>
				) : (
					<Loader />
				)}
			</ContentSection>
			{/* </div> */}
		</>
	);
}
