import React, { useState, useEffect } from 'react'; // useState
import { makeStyles } from '@material-ui/core/styles';

import { Drawer, Typography, Avatar } from '@material-ui/core';

import AppNav from './appNav';

import NavUser from './user';
import Style from './navMUI.module.scss';

import { useNavItems } from 'components/lib';

//import Style from './navMUI.module.scss'; //TODO

const drawerWidth = '16em';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
	},
	appBar: {
		width: `calc(100% - ${drawerWidth}px)`,
		marginLeft: drawerWidth,
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		fontSize: '16px',
	},
	drawerPaper: {
		width: drawerWidth,
		backgroundColor: theme.palette.background.sidebar,
		border: 0,
	},
	drawerDivider: {
		// marginTop: 17
	},
	// necessary for content to be below app bar
	toolbar: {
		// ...theme.mixins.toolbar,
		// [theme.breakpoints.up('md')]: {
		minHeight: 80,
		// },
	},
	content: {
		flexGrow: 1,
		//backgroundColor: '#ffffff', //theme.palette.background.sidebar,
		padding: theme.spacing(3),
	},
}));

export function AppNavMUI() {
	const classes = useStyles();
	let { selectedLogo, setUpdateLogo } = useNavItems();

	const [state, setState] = useState({ type: '', val: '' });

	// useEffect(() => {
	// 	setUpdateLogo('name');
	// }, []);
	useEffect(() => {
		setState(selectedLogo);
	}, [selectedLogo]);

	return (
		<Drawer
			className={Style.drawer}
			variant='permanent'
			classes={{
				paper: Style.drawerpaper,
			}}
			anchor='left'
		>
			<div>
				<div className={classes.toolbar}>
					{state.type === 'picture' ? (
						<img
							style={{
								maxHeight: '103.5px',
								margin: '0 auto',
							}}
							src={
								state.val ? process.env.REACT_APP_API_URI + `/image/${state.val}` : ''
							}
							alt=' '
							onError={(i) => {
								i.target.style.width = '100%';
								i.target.style.height = '60px';
								i.target.style.opacity = 0;
							}}
						/>
					) : (
						<Typography
							variant='h2'
							gutterBottom
							align='center'
							color='textPrimary'
							style={{ marginTop: '0.5625em' }}
						>
							{state.val}
						</Typography>
					)}
				</div>
				<AppNav />
			</div>

			<NavUser />
		</Drawer>
	);
}
