import React, { useEffect, useState } from 'react';

import { Grid, CircularProgress } from '@material-ui/core';
import { randPwd } from 'components/lib';
import { ProfileInfo } from './profileCards/profileInfo';
import { LoginInfo } from './profileCards/loginInfo';
import { ProfilePicture } from './profileCards/profilePicture';

export function ProfileWrapper(props) {
	const [user, setUser] = useState(false);
	let newUser = false;
	useEffect(() => {
		setUser({ ...JSON.parse(localStorage.getItem('user')) });
	}, [newUser]);

	return (
		<>
			{!user ? (
				<CircularProgress />
			) : (
				<Grid
					container
					direction='row'
					justify='center'
					alignItems='center'
					spacing={2}
					style={{ marginTop: '3em' }}
				>
					<ProfileInfo userData={user} />
					<LoginInfo randPwd={randPwd} userData={user} />
					<ProfilePicture userData={user} />
				</Grid>
			)}
		</>
	);
}
