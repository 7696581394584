//import { Home } from 'views/website/home';
import { Home } from 'views/website/home';
// import { Pricing } from 'views/website/pricing';
// import { Terms } from 'views/website/terms';
// import { Privacy } from 'views/website/privacy';

export default [
	// {
	// 	path: '/',
	// 	view: Home,
	// 	layout: 'home',
	// 	title: 'Gravity - SaaS Boilerplate for Node.js',
	// },
	{
		path: '/',
		view: Home,
		layout: 'homeMUI',
		title: 'RF',
	},
	// {
	// 	path: '/pricing',
	// 	view: Pricing,
	// 	layout: 'home',
	// 	title: 'Fruitful Source',
	// },
	// {
	// 	path: '/privacy',
	// 	view: Privacy,
	// 	layout: 'home',
	// 	title: 'Fruitful Source',
	// },
	// {
	// 	path: '/terms',
	// 	view: Terms,
	// 	layout: 'home',
	// 	title: 'Fruitful Source',
	// },
];
