/*
    Custom MaterialUI components

:reused and custom components exports will be placed here for consistency
*/

// view

export { View } from './view/view'; //reused
export { Modal } from './modal/modal'; //reused
export { Notification } from './notification/notification'; //reused

export { CardMUI } from '../components/card/card';
export { CardAlt } from '../components/card/cardAlt';

export { Card } from '../components/cardR/card'; //reused

export { Animate } from '../components/animate/animate'; //reused

// context
export { NavProvider, useNavItems } from 'components/navMUI/context/navContext';
export { AgentProvider, useAgentInfo } from 'components/helpers/context/agent';
export { RoleProvider, useRoleItems } from 'components/helpers/context/roles';
export {
	MemberProvider,
	useMemberInfo,
} from 'components/helpers/context/member';

export { ViewContext } from './view/view'; //reused
export { AuthContext, AuthProvider } from '../app/auth'; //reused

// history
export const History = require('history').createBrowserHistory(); //reused
// layout
export { AppLayoutMUI } from './layout/appMUI/appMUI';
export { AuthLayoutMUI } from './layout/auth/auth';
export { HomeLayout as HomeLayoutMUI } from './layout/home/home';
// nav
export { AppNavMUI } from './navMUI/navMUI';
export { NavTabs } from './navTabs/navTabs';

// progress

export { ProgressBar } from './progress/bar/bar';
// stats & charts

export { LineChart } from './charts/lineChart';
export { PieChart } from './charts/pieChart';
export { BarChart } from './charts/barChart';
export { ChartAlt } from './charts/ChartAlt';
export { BarChart as BarChartAlt } from './charts/barChartNew';

// form
export { Form } from './form/form';
export { PaymentForm } from './form/form';
export { Switch } from './form/switch/switch';
export { Checkbox } from './form/checkbox/checkbox';
export { Radio } from './form/radio/radio';
export { Error } from './form/error/error';
export { Fieldset } from './form/fieldset/fieldset';
export { CardInput } from './form/input/card';
export { EmailInput } from './form/input/email';
export { HiddenInput } from './form/input/hidden';
export { NumberInput } from './form/input/number';
export { PasswordInput } from './form/input/password';
export { PhoneInput } from './form/input/phone';
export { TextInput } from './form/input/text';
export { URLInput } from './form/input/url';
export { Label } from './form/label/label';
export { Legend } from './form/fieldset/legend';
export { Select } from './form/select/select';
export { FormHeader } from './form/header';
// list
export { TableList } from '../components/table/table';

// message
// homepage
// the rest
export { HeaderMUI } from './headerMUI/headerMUI';
export { HeaderMUIGen } from './header/headerMUIGen';

export { Wrapper, PageWrapper } from './wrapper/wrapper';
export { ContentSection } from './contentSection/contentSection';

export { Select as SelectMUI } from './select/select';

export { SwitchBtns, SwitchBtnsPlchld } from '../components/button/switchBtns';

export { Loader } from './loader/loader';

//reused
export { Button } from '../components/button/button'; //reused
export { Link } from './link/link'; //reused
export { Icon } from './icon/icon'; //reused
// hooks

export {
	useComponentWillMount,
	useCheckAuth,
	getDate,
	currencyFormatter,
	dataValidator,
} from './helpers';

// hooks reused
export { useAPI } from './hooks/api';
export { usePlans } from './hooks/plans';
export { usePermissions } from './hooks/permissions';

// widgets

export { StatsCard } from './widgets/statsCard/statsCard';
export { ComparisonCard } from './widgets/comparisonCard/comparisonCard';
export { TopPerformersCard } from './widgets/leaderBoard/leaderboard';

//helpers

export {
	ConditionalWrapper,
	randPwd,
	currencyFormatterCompact,
	percIncrease,
	apiAxiosCall,
} from './helpers/index';
