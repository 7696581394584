/***
 *
 *   HOME LAYOUT
 *   Main website layout
 *
 **********/
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { HeaderMUIGen } from 'components/lib';
import { ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline, Button, ButtonGroup } from '@material-ui/core';
import { darkTheme } from 'components/global/material';

export function HomeLayout(props) {
	let token = JSON.parse(localStorage.getItem('accessToken'));

	return (
		<ThemeProvider theme={darkTheme}>
			<CssBaseline />

			<main className='home'>
				<HeaderMUIGen title=' ' type='largeHeader-nosidebar'>
					<ButtonGroup
						color='primary'
						aria-label='text primary button group'
						size='large'
					>
						<Button component={RouterLink} to='/signup'>
							Sign Up
						</Button>
						{token || (
							<Button component={RouterLink} to='/signin'>
								Sign In
							</Button>
						)}
					</ButtonGroup>
				</HeaderMUIGen>
				{<props.children {...props.data} />}
			</main>
		</ThemeProvider>
	);
}
