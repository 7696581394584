/***
 *
 *   useAPI hook
 *   Interact with API calls – handle errors, return loading state and data
 *
 *   PROPS
 *   url: endpoint url
 *   method: get, post, put etc.. (default: get)
 *   body: request body (optional)
 *
 **********/

import { useState, useEffect, useContext, useCallback, useRef } from 'react';
import Axios from 'axios';
import { ViewContext } from 'components/lib';

export function useAPI(url, method, body) {
	// context & state
	const context = useRef(useContext(ViewContext));
	const [state, setState] = useState({ data: null, loading: false });
	//Axios.defaults.baseUrl = process.env.REACT_APP_API_URI;

	const fetch = useCallback(async () => {
		try {
			if (!url) {
				setState({ data: null, loading: false });
				return false;
			}
			//const config = {};

			setState({ loading: true });
			const res = await Axios({
				url: process.env.REACT_APP_API_URI + url,
				method: method || 'get',
				body: body,
			});
			setState({ data: res.data, loading: false });
		} catch (err) {
			context?.current && context.current.handleError(err);
		}
	}, [url, method, body, context]);

	useEffect(() => {
		fetch();
	}, [fetch]);

	return state;
}
